import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Dropoffliststate, dropofflistItem } from "../interface/drop-off-list_interface";
import { AppState } from "../store";

const initialState: Dropoffliststate = {
  dropofflist:[],
    totalRecords: 0
};

export const dropofflistreducer = createSlice({
  name: "loundryState",
  initialState,
  reducers: {
    setdropofflistdata: ( state, action: PayloadAction<dropofflistItem[]> ) => ({
      ...state,
      dropofflistItem: action.payload,
    }),
  },
});

export const { setdropofflistdata } = dropofflistreducer.actions;

export const getdropofflistdata = (state: AppState) => state.dropofflistreducer.dropofflist;

export { initialState as loundryState };
export default dropofflistreducer;
