import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createpickupcodeItem, createpickupcodestate } from "../interface/create_pickupcode_interface";
 
import { AppState } from "../store";

const initialState: createpickupcodestate = {
    createpickupcode:[],
    totalRecords: 0
};

export const createpickupcodereducer = createSlice({
  name: " createpickupcodestate",
  initialState,
  reducers: {
    setcreatepickupcode: ( state, action: PayloadAction<createpickupcodeItem[]> ) => ({
      ...state,
      createpickupitem: action.payload,
    }),
  },
});

export const { setcreatepickupcode } = createpickupcodereducer.actions;

export const getdropofflistdata = (state: AppState) => state.Createpickupcodereducer.createpickupcode;

export { initialState as createpickupcodestate  };
export default createpickupcodereducer;
