import { HashRouter, Route, Routes } from "react-router-dom";
import Forgetpassword from "../../Screens/Login/forget/Forgetpassword";
import Setnewpassword from "../../Screens/Login/forget/Setnewpasword";
import Verifyopt from "../../Screens/Login/forget/Verifyotp";
import Login from "../../Screens/Login/Login";
import Dashbord from "../../Screens/Dashbord/Index";
import Dropofflist from "../../Screens/Drop_off_List/Drop-off-List";
import Sidebar from "../../Screens/layout/Sidebar/Sidebar";
import Intagging from "../../Screens/tag_garments/tag_garments";
import User from "../../Screens/User/User";
import Report from "../../Screens/Report/Report";
import Setting from "../../Screens/Setting/Setting";
import Dropoffcode from "../../Screens/Drop_off_List/Drop-off-list-code";
import Drycleaning from "../../Screens/tag_garments/Drycleaning";
import Laudry from "../../Screens/tag_garments/Laudry";
import Washfolds from "../../Screens/tag_garments/Wash-folds";
import Quicksort from "../../Screens/Quicksort/Quicksort";
import ConveyorAlert from "../../Screens/Quicksort/ConveyorAlert";
import ConveyorInformation from "../../Screens/Quicksort/ConveyorInformation";
import PendingOrderInformation from "../../Screens/Quicksort/PendingOrderInformation";
import SortReports from "../../Screens/Quicksort/SortReports";
import Garment from "../../Screens/Quicksort/Garment";
import Quicksort_1 from "../../Screens/Quicksort/QuickSort_1";
import SettingEdit from "../../Screens/Setting/Setting_Edit";
import SettingPassword from "../../Screens/Setting/Setting_Change_pwd";
import Orders from "../../Screens/Orders/Orders";
import { Avatar, Button, Grid, Icon, IconButton, Menu, MenuItem } from "@mui/material";
import Confirm_pickup from "../../Screens/confirm pickup/confirm_pickup";
import Tag_garments from "../../Screens/tag_garments/tag_garments";
import Mark_clean from "../../Screens/mark_clean/mark_clean";
import Mark_cleaned from "../../Screens/mark_clean/mark_clean";
import Create_pickup_order from "../../Screens/create_confirm_tag/create_pickup_order";
import Viewdetail from "../../Screens/Drop_off_List/View_Details";
import View_Details from "../../Screens/confirm pickup/view_details";
import { ExitToApp, LogoutSharp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Drivers from "../../Screens/Drivers/Drivers";

function AppRouter() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [full_name, setFull_name] = useState("");
  const open = Boolean(anchorEl);
  const Logout = () => {
    window.sessionStorage.setItem("@userToken", "");
    window.location.href = "/login";
  };
  var userdata: any = window.sessionStorage.getItem("@userData")
  useEffect(() => {
    userdata = JSON.parse(userdata);
    setFull_name(userdata.full_name);
  }, [])
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <HashRouter>
      <Grid container
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-end" className="logoutbar">
        <Avatar sx={{ bgcolor: "#1976d2", marginTop: "10px", marginRight: "10px" }} onClick={handleClick}>
          <ExitToApp sx={{ fontSize: 30 }} className="fa-plus-circle" />
        </Avatar>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>{full_name}</MenuItem>
          <MenuItem onClick={Logout}>Logout<LogoutSharp /></MenuItem>
        </Menu>
      </Grid>
      <Routes>
        {/* <Route path="/" element={<Dashbord />} /> */}
        <Route path="/dashbord" element={<Dashbord />} />
        <Route path="/" element={<Dropofflist />} />
        <Route path="/dropoff-list" element={<Dropofflist />} />

        <Route path="/view-details" element={<Viewdetail />} />
        <Route path="/sort-bags" element={<Tag_garments />}>
          <Route index={true} element={<Laudry />} />
          <Route path="/sort-bags/laundry" element={<Laudry />} />
          <Route path="/sort-bags/drycleaning" element={<Drycleaning />} />
          <Route path="/sort-bags/wash-folds" element={<Washfolds />} />
        </Route>
        {/* <Route path="/quicksort" element={<Quicksort />} >
          <Route index={true} element={<Quicksort_1 />} />
          <Route path="/quicksort/conveyor-alert" element={<ConveyorAlert />} />
          <Route path="/quicksort/conveyor-information" element={<ConveyorInformation />} />
          <Route path="/quicksort/pending-order-information" element={<PendingOrderInformation />} />
          <Route path="/quicksort/sort-reports" element={<SortReports />} />
          <Route path="/quicksort/garment" element={<Garment />} />
        </Route> */}
        <Route path="/mark-cleaned" element={<Mark_cleaned />} />
        <Route path="/confirm_pickup" element={<Confirm_pickup />} />

        <Route path="/view_details" element={<View_Details />} />
        <Route path="/create-pickup-order" element={<Create_pickup_order />} />
        <Route path="/user" element={<User />} />
        <Route path="/report" element={<Report />} />
        <Route path="/Dropoffcode" element={<Dropoffcode />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/setting_edit" element={<SettingEdit />} />
        <Route path="/setting_change_pwd" element={<SettingPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<Forgetpassword />} />
        <Route path="/verify-otp" element={<Verifyopt />} />
        <Route path="/reset-password" element={<Setnewpassword />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/drivers" element={<Drivers />} />
      </Routes>
      <Sidebar />
    </HashRouter>
  );
}
export default AppRouter;
