import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { fetchmarkcleaned } from "../../Redux/fetchservice/mark_cleaned";
import swal from "sweetalert";
import moment from "moment";
import { LoaderBullet } from "../../components/loader";
import { fetchcreatepickupcode } from "../../Redux/fetchservice/create_pickupcode";
import { Clear, Navigation, Search } from "@mui/icons-material";
import { useNavigate } from "react-router";

var Pickupcode = ''
export default function Mark_cleaned() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchedVal, setSearchedVal] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [checkbox, setCheckbox] = React.useState<String[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [msg, setMsg] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [btn, setBtn] = React.useState(false);
  const [totalRecords, setTotalRecords] = React.useState(0);



  var userdata: any = window.sessionStorage.getItem("@userData")
  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const pickuporderlist = async () => {
    userdata = JSON.parse(userdata);

    let payload = {
      status: "3",
      id: userdata.id
    };
    const response = await fetchmarkcleaned(payload);
    if (response.data) {
      setData(response.data);
      if (response?.data[0]?.totalCount) {
        setTotalRecords(response.data[0].totalCount);
      } else {
        setTotalRecords(0);
      }
    }
    response.data ? setLoading(false) : setLoading(true);
    if (response.status === 404) {
      setMsg(true);
    }
  };

  React.useEffect(() => {
    pickuporderlist();

  }, []);




  React.useEffect(() => {

    if (checkbox?.length > 0) {
      setBtn(true)
    } else {
      setBtn(false)
    }
  }, [checkbox?.length]);

  const createpickuporderlist = async () => {
    let payload = {
      bags: checkbox,
    };

    const response: any = await fetchcreatepickupcode(payload);
    Pickupcode = JSON.stringify(response.data.code.code)
    await swal("Your pickup code is ", Pickupcode, "success", {

    }).then((okay) => {
      if (okay) {
        navigate("/confirm_pickup");
      }
    })
    pickuporderlist()
  };
  const handleCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setCheckbox((prev: any[]) => [...prev, { bag_id: e.target.value }]);
    } else {
      const data: any = checkbox.filter((item: any) => item !== e.target.value);
      checkbox.splice(checkbox.indexOf(data), 1)
    }
  };
  const Alert = () => {
    swal("Are you sure?", "Create pickup code for bags.", "warning", {
      buttons: ["CANCEL", "ACCEPT"],
      dangerMode: true,
    }).then(async (okay) => {
      if (okay) {
        createpickuporderlist();

      }
    })
  };

  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4" color="black">
          {" "}
          Create Pickup Order
        </Typography>
        <TextField
          style={{ marginLeft: "380px" }}
          label="search"
          size="small"
          sx={{ height: " 1em !implements" }}
          value={searchedVal}
          onChange={(e) => setSearchedVal(e.target.value)}
          InputProps={{
            endAdornment: searchedVal ? (
              <IconButton onClick={(e) => setSearchedVal("")}><Clear /></IconButton>
            ) : (<IconButton><Search /></IconButton>),
          }}
        />
        <Grid>
          {" "}
          {checkbox.length > 0 ? (
            <Button variant="contained" onClick={Alert}>
              create pickup
            </Button>
          ) : (
            <Button variant="contained" disabled onClick={Alert}>
              create pickup
            </Button>
          )}
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 560,
          minHeight: 560,
          bgcolor: "white",
          scrollBehavior: "none",
          overflow: "auto",
          width: "90%",
          marginLeft: "5%",
        }}
      >
        {totalRecords === 0 ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: 560 }}
          >
            <Grid item xs={3}>
              <h1>Create pickup order is empty. </h1>
            </Grid>
          </Grid>
        ) : loading ? (
          <div className="loader">
            <LoaderBullet />
          </div>
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  position: "sticky",
                  top: "0",
                  background: "#90caf9",
                  zIndex: "2",
                }}
              > <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                  ORDER ID
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Bag ID
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Customer Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Total Items
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Delivery Date
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Status
                </TableCell>

                <TableCell sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}>Pickup By Driver</TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data
                .filter(
                  (item: any) =>
                    !searchedVal.length ||
                    item.order_id
                      .toString()
                      .toLowerCase()
                      .includes(searchedVal.toString().toLowerCase()) ||
                    item.tag_id
                      .toString()
                      .toLowerCase()
                      .includes(searchedVal.toString().toLowerCase())
                )
                .map((i: any, key) =>
                  <TableRow hover role="checkbox" sx={{ height: "50px" }} key={key}>
                    <TableCell>

                      {i.order_id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {i.tag_id}
                    </TableCell>
                    <TableCell>
                      {i?.full_name}
                    </TableCell>
                    <TableCell>
                      {i.quantity || 0}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {moment(
                        i?.delivery_date
                      ).format("MM-DD-YYYY")}{" "}
                    </TableCell>
                    <TableCell>
                      {i.status === "3" ? <p>Cleaned</p> : null}{" "}
                    </TableCell>
                    <TableCell >
                      {(i.pickup_by_driver === "0") ?
                        (<>
                          No
                        </>)
                        :
                        (<>
                          Yes
                        </>)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        value={i.dispatch_tag_id}
                        onChange={handleCheckboxChange}
                      />
                    </TableCell>


                  </TableRow>

                )
              }

            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
