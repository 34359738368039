import { HashRouter, Route, Routes } from "react-router-dom";
import Forgetpassword from "../../Screens/Login/forget/Forgetpassword";
import Setnewpassword from "../../Screens/Login/forget/Setnewpasword";
import Verifyopt from "../../Screens/Login/forget/Verifyotp";
import Login from "../../Screens/Login/Login";

function PublicRouter() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<Forgetpassword />} />
        <Route path="/verify-otp" element={<Verifyopt />} />
        <Route path="/reset-password" element={<Setnewpassword />} />
      </Routes>
    </HashRouter>
  );
}
export default PublicRouter;
