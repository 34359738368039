import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { fetchIntagging } from "../../Redux/fetchservice/Intaggging_fetch";
import { fetchProduct } from "../../Redux/fetchservice/Product_fetch";
import moment from "moment";
import swal from "sweetalert";
import { fetchconfirmandcreate } from "../../Redux/fetchservice/confirmandcreate";
import { fetchconfirm_tag } from "../../Redux/fetchservice/confirm_tag";


const Drycleaning = () => {
  var [bag_no, setBag_no] = useState("");
  const [page, setPage] = React.useState(1);
  const [full_name, setFullName] = useState("");
  const [delivery, setDelivery] = useState("");
  const [bag_id, setBag_id] = useState("");
  const [number_of_bags, setNumber_of_bags] = useState("");
  const [data, setData] = useState("");
  const [data1, setData1] = useState<any[]>([]);
  const [product, setProduct] = useState<String[]>([]);
  const [open, setOpen] = React.useState(false);
  const [order_dispatch_tag_id, setOrder_dispatch_tag_id] = React.useState("");
  const [order_id, setOrder_id] = React.useState("");
  const [showhide, setShowhide] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [id, setId] = useState("");
  const [category, setCategory] = useState()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const confirm_tag = async () => {
    let payload = {
      id: id,
      status: "2",
    };
    const response = await fetchconfirm_tag(payload);
  };

  const increaseQuantity = (index: any) => {
    const currentItems = [...data1];
    currentItems[index].quantity += 1;
    setData1(currentItems);
  };

  const decreaseQuantity = (index: any) => {
    const currentItems = [...data1];

    if (currentItems[index].quantity > 0) {
      currentItems[index].quantity -= 1;
      setData1(currentItems);
    }
  };
  const fetchUsers = async () => {
    let payload = {
      tag_id: bag_no,
    };
    let response: any = await fetchIntagging(payload);

    setError(response.message ? response.message : response.errors[0].msg);
    if (response != undefined && response.success) {
      setShowhide(false);
      setData(response.data);
      setCategory(response.data.order_dispatch_tag_detail.orderDetail.category_id)
      setFullName(
        response.data.order_dispatch_tag_detail.orderDetail.userDetail?.full_name
      );
      setDelivery(
        response.data.order_dispatch_tag_detail.orderDetail.delivery_date
      );
      setBag_id(response.data.tag_id);
      setNumber_of_bags(
        response.data.order_dispatch_tag_detail.orderDetail.number_of_bags
      );
      setOrder_id(response.data.order_dispatch_tag_detail.order_id);
      setOrder_dispatch_tag_id(response.data.order_dispatch_tag_detail.id);
      setId(response.data.id);
    }
  };
  const fetchProductData = async () => {
    let payload = {
      category_id: "12",
    };
    let response: any = await fetchProduct(payload);
    setData1(response.result);
    setLoading(false);
  };

  const confirmandcreat = async () => {
    let productData: any = [];
    data1
      .filter((x: any) => x.quantity > 0)
      .map((item, key) => {
        let product = { product_id: item.id, quantity: item.quantity };
        productData.push(product);
      });
    let payload = {
      order_id: order_id,
      order_dispatch_tag_id: order_dispatch_tag_id,
      product: productData,
    };

    const response = await fetchconfirmandcreate(payload);
    if (response.successs) {
      confirm_tag();
      swal(response.message, "  ", "success");
      setShowhide(true);
      setBag_no("")
    }
  };
  const questionAlert = () => {
    swal("Are you sure?", " You want to confirm and proceed tag!", "warning", {
      buttons: ["CANCEL", "ACCEPT"],
      dangerMode: true,
    }).then((okay) => {
      if (okay) {
        confirmandcreat();
        fetchUsers();
        setShowhide(true);

      }
    });
  };
  useEffect(() => {
    fetchProductData();
  }, []);

  return (
    <>
      <Grid>
        <Typography variant="h4" padding="10px 5%">
          Sort Bags
        </Typography>
        <Grid>
          <Grid
            container
            direction="column"
            width="90%"
            marginLeft="5%"
            bgcolor="white"
            sx={{ padding: "10px", marginBottom: "20px", borderRadius: "5px" }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6"> Please enter Bag ID </Typography>
            </Stack>
            <Stack direction="row" spacing={3} margin="10px">
              <form action="javascript:void(0)" onSubmit={fetchUsers}>
                <TextField
                  // required
                  size="small"
                  label="Enter Bag ID"
                  value={bag_no}
                  onChange={(e: any) => setBag_no(e.target.value)}
                  sx={{ marginLeft: "-10px" }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ marginLeft: "10px" }}

                >
                  Submit
                </Button>
              </form>
              {/* <p style={{ marginTop: "10px" }}>OR</p>
              <Button variant="contained">Scan</Button> */}
              <br />
            </Stack>
            {error === "Order detail not found for tag." ? (
              <p className="error"> Bag id was not found in your drop off list.</p>
            ) : error === "The tag_id field is required." ? (
              <p className="error">The tag id field is required.</p>
            ) : error === "Tag is already used." ? (
              <p className="error">Garments of this bag have already been processed.</p>
            ) : error ===
              "Order not yet accepted by laundry, first accept order and then decide." ? (
              <p className="error">
                Order not yet accepted by laundry, first accept order and then
                decide.
              </p>
            ) : null}
            {showhide ? (
              <></>
            ) : (
              <Grid sx={{ paddingLeft: "10px", borderTop: "1px solid gray" }}>
                <Typography>
                  <b>Lot :</b> 07022202502
                </Typography>
                <Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>User Name : {full_name}</Typography>
                    <Typography sx={{ width: "300px" }}>
                      Needs Delivery by :{" "}
                      {moment(delivery).format("DD-MM-YYYY")}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>Bag id :{bag_id}</Typography>
                    <Typography sx={{ width: "300px" }}>
                      Bag {page}/ {number_of_bags}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            )}
          </Grid>
          {showhide ? (
            <>
              <Box sx={{ height: "380px" }}></Box>
            </>
          ) : (
            <Grid>
              <TableContainer
                sx={{
                  bgcolor: "white",
                  scrollBehavior: "none",
                  overflow: "auto",
                  width: "90%",
                  marginLeft: "5%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: "0",
                        background: "#90caf9",
                        zIndex: "2",
                      }}
                    >
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "550",
                          textTransform: "uppercase",
                        }}
                      >
                        Enter Clothes
                      </TableCell>

                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "550",
                          textTransform: "uppercase",
                        }}
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "550",
                          textTransform: "uppercase",
                        }}
                      >
                        Brands
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data1.map((val: any, i: any) => (
                      <TableRow sx={{ height: "50px" }}>
                        <TableCell
                          sx={{ fontSize: "15px" }}
                          key={i}
                          data-id={val.id}
                        >
                          {val.title}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => {
                              decreaseQuantity(i);
                            }}
                          >
                            <Remove />
                          </Button>
                          <TextField
                            type="pin"
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{ margin: "0 10px" }}
                            value={val.quantity}
                            size="small"
                          />
                          <Button
                            variant="contained"
                            onClick={() => {
                              increaseQuantity(i);
                            }}
                          >
                            <Add />
                          </Button>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                paddingRight="5%"
                margin=" 20px 0"
              >

                <Button variant="contained" onClick={questionAlert}>
                  Confirm & Proceed{" "}
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>
                    Are You Sure To Confirm and Proceed Tag
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={handleClose}>Confirm</Button>
                    <Button onClick={handleClose} autoFocus>
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>

          )}
          {category === 12 ?
            <Grid container direction="row" justifyContent="space-around" style={{ marginBottom: '20px' }} >
              <Button variant="contained" color="success" style={{ fontSize: '20px' }}>
                Laundry
              </Button>

            </Grid> :
            category === 15 ?
              <Grid container direction="row" justifyContent="space-around" style={{ marginBottom: '20px' }}  >
                <Button variant="contained" color="warning" style={{ fontSize: '20px' }}>
                  Drycleaning
                </Button>

              </Grid>
              :
              category === 1 ?
              <Grid container direction="row" justifyContent="space-around" style={{ marginBottom: '20px' }}  >
                <Button variant="contained" style={{ fontSize: '20px' }}> Wash & Folds</Button>
              </Grid>
              :
              null}

          <Stack spacing={2}></Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Drycleaning;
