import { fetchForgetPasswordData } from "../fetchservice/UserManagement";
import { setUserPasswordData } from "../Reducer/userPasswordReducer";
import { AppThunk } from "../store";
import { setUserData } from "../../helper/Storage";
import swal from "sweetalert";

export const setUserForgetpasswordData =
  (payload: any, navigate: any): any =>
  async (dispatch: any) => {
    let request = {
      value: payload.email.value,
    };
    const response: any = await fetchForgetPasswordData(request);

    if (response != undefined && response.success) {
      let userObj: any = JSON.stringify(response.data);
      swal(response.message, "  ", "success");
      setUserData(userObj);
      dispatch(setUserPasswordData(userObj));
      navigate("/verify-otp", { state: request });
    } else {
      dispatch(setUserPasswordData([]));
      swal(response.message, "  ", "error");
      if (response && response[0] && response[0].errorMessage) {
        let payload = {
          status: "error",
          message: response[0].errorMessage,
          display: true,
        };
      }
    }
  };
