import { createLogger } from "redux-logger";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userManagementReducer from "./Reducer/userLoginReducer";
import userPasswordReducer from "./Reducer/userPasswordReducer"
import userOtpReducer from "./Reducer/userOtpReducer";
import dropofflistreducer from "./Reducer/drop-off-list_reducer";
import dropoffliscodetreducer from "./Reducer/drop-off-lidt-code_reducer";
import userResetPasswordReducer from "./Reducer/userResetPasswordReducer"
import userlistreducer from "./Reducer/user_reducer";
import IntaggingReducer from "./Reducer/Intagging_Reducer";
import ProductReducer from "./Reducer/Product_Reducer"
import useraddreducer from "./Reducer/user-add_reducer";
import userremovereducer from "./Reducer/user-remove_reduce";
import usereditreducer from "./Reducer/user-edit_reducer";
import roleprmissionreducer from "./Reducer/role-permission_reducer";
import confirmandcreatereducer from "./Reducer/confirmandcreate_reducer";
import markcleanedreducer from "./Reducer/mark-cleaned_reducer";
import createpickupcodereducer from "./Reducer/create_pickupcode_reducer";
import pickuplistreducer from "./Reducer/pickup_list_reducer";
import driverReducer from "./Reducer/Driver_reducer";
import TagAllocateReducer from "./Reducer/Tag_Allocate_Reducer"
import AssignTagToCustomerReducer from "./Reducer/Assign_Tag_To_Customer_Reducer";



const logger = createLogger();

export const store = configureStore({
  reducer: {
    
    userManagement: userManagementReducer.reducer,
    userPasswordReducer: userPasswordReducer.reducer,
    userOtpReducer: userOtpReducer.reducer,
    userResetPasswordReducer: userResetPasswordReducer.reducer,
    dropofflistreducer: dropofflistreducer.reducer,
    dropofflistcodereducer: dropoffliscodetreducer.reducer,
    userlistreducer:userlistreducer.reducer,
    IntaggingReducer: IntaggingReducer.reducer,
    ProductReducer:ProductReducer.reducer,
    usereditreducer:usereditreducer.reducer,
    rolepermissionreducer:roleprmissionreducer.reducer,
    useraddreducer:useraddreducer.reducer,
    userremovereducer:userremovereducer.reducer,
    createandconfirmreducer:confirmandcreatereducer.reducer,
    markcleanedreducer:markcleanedreducer.reducer,
    Createpickupcodereducer:createpickupcodereducer.reducer,
    pickuplistreducer:pickuplistreducer.reducer,
    driverReducer:driverReducer.reducer,
    TagAllocateReducer:TagAllocateReducer.reducer,
    AssignTagToCustomerReducer:AssignTagToCustomerReducer.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type AppState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;

export type commonState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

 