import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  pickuplistitem,
  pickupliststate,
} from "../interface/pickuplist_interface";
import { AppState } from "../store";

const initialState: pickupliststate = {
  pickuplist: [],
  totalRecords: 0,
};

export const pickuplistreducer = createSlice({
  name: "loundryState",
  initialState,
  reducers: {
    setpickuplistdata: (state, action: PayloadAction<pickuplistitem[]>) => ({
      ...state,
      pickuplistitem: action.payload,
    }),
  },
});

export const { setpickuplistdata } = pickuplistreducer.actions;

export const getpickuplistdata = (state: AppState) =>
  state.pickuplistreducer.pickuplist ;

export { initialState as loundryState };
export default pickuplistreducer;
