import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {ProductState,ProductItem,} from "../interface/Product_Interface";
import { AppState } from "../store";

const initialState: ProductState = {
  Product: [],
  totalRecords: 0,
};

export const ProductReducer = createSlice({
  name: "ProductState",
  initialState,
  reducers: {
    setProductData: (state, action: PayloadAction<ProductItem[]>) => ({
      ...state,
      ProductItem: action.payload,
    }),
  },
});

export const { setProductData } = ProductReducer.actions;

export const getProductData = (state: AppState) =>
  state.ProductReducer.Product;

export { initialState as ProductState };
export default ProductReducer;
