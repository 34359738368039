import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AssignTagToCustomerInterface,AssignTagItem } from "../interface/Tag_Allocate_interface";
import { AppState } from "../store";

const initialState: AssignTagToCustomerInterface = {
  assignlist:[],
    totalRecords: 0
};

export const AssignTagToCustomerReducer = createSlice({
  name: "loundryState",
  initialState,
  reducers: {
    setAssignTagData: ( state, action: PayloadAction<AssignTagItem[]> ) => ({
      ...state,
      AssignTagItem: action.payload,
    }),
  },
});

export const { setAssignTagData } = AssignTagToCustomerReducer.actions;

export const getAssignTagData = (state: AppState) => state.AssignTagToCustomerReducer.assignlist;

export { initialState as loundryState };
export default AssignTagToCustomerReducer;
