import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { rolepermission, rolepermissionItem } from "../interface/role_permission";
import { userlist, userlistitem} from "../interface/userlist_interface";
import { AppState } from "../store";

const initialState: rolepermission = {
    rolepermission:[],
    totalRecords: 0
};

export const roleprmissionreducer = createSlice({
  name: "user list",
  initialState,
  reducers: {
    setrolepermiossiom: ( state, action: PayloadAction<rolepermissionItem[]> ) => ({
      ...state,
      rolepermissionItem: action.payload,
    }),
  },
});

export const { setrolepermiossiom  } = roleprmissionreducer.actions;

export const getuserlist = (state: AppState) => state.rolepermissionreducer.rolepermission;

export { initialState as rolepermission };
export default  roleprmissionreducer;

