import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TablePagination, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import pic from "../../../src/Assets/Images/pickup1.jpg";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { CheckOutlined, Close } from "@mui/icons-material";
import { acceptrejectorder } from "../../Redux/fetchservice/acceptreject";
import swal from "sweetalert";
import { fetchDropoffListData } from "../../Redux/fetchservice/drop-off-list";


export default function View_Details() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [checkbox, setCheckbox] = React.useState<String[]>([]);
  const location = useLocation();
  const code: any = location.state;
  const data = code.data;
  console.log(data);



  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // const checkhandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(event.target.checked);
  // };
  const handleCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setCheckbox((prev: any[]) => [...prev, { bag_id: e.target.value }]);
    } else {
      const data: any = (checkbox.filter((item: any) => item !== e.target.value));
      (checkbox.splice((checkbox).indexOf(data), 1))
    }
  };

  // const RemoveItem = async (item: any) => {
  //   let payload = {
  //     id: item,
  //   };
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   const response = await DeleteDriverapi(payload);
  // };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // const showTags = (dispatchTags: any) => {
  //   const length = dispatchTags.length
  //   let tagData: any = "";
  //   dispatchTags.map((item: any) => {

  //     return tagData = tagData + "  " + item.tagDetail.tag_id + (length > 1 ? (",") : (""));
  //   })
  //   return (tagData);
  // }

  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4" color="black">
          {" "}
          Details{" "}
        </Typography>
        <Link to="/confirm_pickup">
          <Button variant="contained">back</Button>
        </Link>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 560,
          minHeight: 560,
          bgcolor: "white",
          scrollBehavior: "none",
          overflow: "auto",
          width: "90%",
          marginLeft: "5%",
        }}
      >

        <Table aria-label="customized table">
          <TableHead>
            <TableRow
              sx={{
                position: "sticky",
                top: "0",
                background: "#90caf9",
                zIndex: "2",
              }}
            >
              {/* <TableCell
              sx={{
                fontSize: "16px",
                fontWeight: "550",
                textTransform: "none",
              }}
            >
                Mark
              </TableCell> */}

              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Order ID
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "none",
                }}
              >
                No. of bags
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Customer Name
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Pickup Date
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Delivery Date
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Bag tags
              </TableCell>
              {/* <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Action
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((x: any) => x.code === code.code)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item: any) => (
                <>
                  {item.pickupCode.map((i: any) => (
                    <TableRow hover sx={{ height: "50px" }}>
                      {" "}
                      {/* <TableCell> <Checkbox
                        value={item.id}
                        onChange={handleCheckboxChange}
                      /></TableCell> */}
                      <TableCell>
                        {i.tagDetail.order_dispatch_tag_detail.orderDetail.id}
                      </TableCell>
                      <TableCell>
                        {i.tagDetail.order_dispatch_tag_detail.orderDetail.number_of_bags}

                      </TableCell>
                      <TableCell>
                        {
                          i.tagDetail.order_dispatch_tag_detail.orderDetail
                            .userDetail?.full_name
                        }
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(
                          i.tagDetail.order_dispatch_tag_detail.orderDetail
                            .pickup_date
                        ).format("YYYY-MM-DD")}{" "}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(
                          i.tagDetail.order_dispatch_tag_detail.orderDetail
                            .delivery_date
                        ).format("YYYY-MM-DD")}{" "}
                      </TableCell>
                      <TableCell>
                       
                        {i.tagDetail.tag_id}
                      </TableCell>
                     
                    </TableRow>
                  ))}
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.filter((x: any) => x.code === code.code).map((val: any) => val.pickupCode.length)}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
