import * as React from "react";
import "./confirm_pickup.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Grid, TablePagination, Typography } from "@mui/material";
import { Pickuplistaction } from "../../Redux/Action/pickup_list_action";
import { fetchpickuplist } from "../../Redux/fetchservice/fetch_pickup_list";
import { useNavigate } from "react-router";
import { LoaderBullet } from "../../components/loader";
import moment from "moment";

export default function Confirm_pickup() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [msg, setMsg] = React.useState(false)
  const navigate = useNavigate();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const pickuplist = async () => {
    let payload = {};
    const response = await fetchpickuplist(payload);
    setData(response.data);
    response.data ? setLoading(false) : setLoading(true);
    if (response.status === 400) {
      setMsg(true);
    }
  };
  React.useEffect(() => {
    pickuplist();
  }, []);

  const viewdetails = (code: any) => {
    navigate("/view_details", { state: { data: data, code: code } });
  };

  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4" color="black">
          {" "}
          Confirm Pickup
        </Typography>
      </Grid>
      <TableContainer
        component={Paper}
        // progressPending={loading}

        sx={{
          maxHeight: 557,
          minHeight: 557,
          bgcolor: "white",
          scrollBehavior: "none",
          overflow: "auto",
          width: "90%",
          marginLeft: "5%",
        }}
      >{data?.length === 0 ? (<Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: 560 }}
      >

        <Grid item xs={3}>
          <h1>Confirm pickup order is empty.</h1>
        </Grid>
      </Grid>) : loading ? (<div className="loader">

        <LoaderBullet />
      </div>
      ) : (
        <Table aria-label="customized table">
          <TableHead>
            <TableRow
              sx={{
                position: "sticky",
                top: "0",
                background: "#90caf9",
                zIndex: "2",
              }}
            >
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Pickup code
              </TableCell>
              {/* <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Driver Name
              </TableCell> */}
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "none",
                }}
              >
                {" "}
                No. of bags
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Date & Time
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                status
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item: any) => (
                <TableRow hover sx={{ height: "50px" }}>
                  <TableCell component="th" scope="item">
                    {item.code}
                  </TableCell>
                  <TableCell>
                    {item.pickupCode
                      .map((itam: any) =>
                        itam.tagDetail.order_dispatch_tag_detail.orderDetail.number_of_bags).reduce((p: any, c: any) => p + c, 0)}
                    
  </TableCell>
                  <TableCell> {(moment(item.created_at).format("YYYY-MM-DD"))}</TableCell>
                  <TableCell>
                    {
                      item.status === "1" ? (
                        <p>Ready For Pickup</p>
                      ) : item.status === "2" ? (
                        <p>Pickedup</p>
                      ) : null
                    }
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        viewdetails(item.code);
                      }}
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>)}
      </TableContainer>
      <TablePagination
        component="div"
        count={data?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
