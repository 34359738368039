import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userItem, userPasswordState } from "../interface/userPassword_Interface";

import { AppState } from "../store";

const initialState: userPasswordState = {
  userlist: [],
  totalRecords: 0
};

export const userPasswordReducer = createSlice({
  name: "userPasswordState",
  initialState,
  reducers: {
    setUserPasswordData: (
      state,
      action: PayloadAction<userItem[]>
    ) => ({
      ...state,
      userManagementDataObj: action.payload,
    }),
  },
});

export const {
    setUserPasswordData
} = userPasswordReducer.actions;

export const getUserManagementData = (state: AppState) => state.userManagement.userlist;

export { initialState as userPasswordState };
export default userPasswordReducer;
