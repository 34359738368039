// import { deleteDriver, setDriverDetailsData, setDriverListData, updateDriverStatus } from "../reducers/driver.reducer";
// import { fetchDriverListData, getDriverDetailsApi, postDriverData, updateDriverApi } from "../fetchService/DriverManagement";
import { AppThunk } from "../store";
import { DeleteDriverapi, fetchDriverlist, PostDriveradd, PutDriveredit } from "../fetchservice/fetch_Driver";
import { setDriverListData } from "../Reducer/Driver_reducer";

export const DriverListAction = (payload: any) :any=> async (dispatch: any) => {
  const response: any = await fetchDriverlist(payload);
  if (response.success) {
    dispatch(setDriverListData(response.data));
  }
};

export const AddDriverAction = (payload: any, navigate: any): any => async (dispatch: any) => {
  const response: any = await PostDriveradd(payload);
  if (response && response.success) {   
    navigate('/drivers');
  } 
}
// export const setDriverData = (payload: any, navigate: any): AppThunk => async (dispatch: any) => {

//   let formData = {
//     full_name: payload.fullName.value,
//     email: payload.email.value,
//     mobile_number: payload.mobile.value,
//     password: "qwerty",//payload.password.value,
//     type: "4",
//     user_type: "4",
//     laundry_allocate: payload.laundryAllocate.value
//   }
    
//   const response: any = await postDriverData(formData);

//   if (response && response.success) {   
//     navigate('/drivers');
//   } 
// };


export const EditDriverAction = (payload: any,navigate: any): AppThunk => async (dispatch: any) => {
  const response: any = await PutDriveredit(payload);
  if (response.success) {
    navigate('/drivers');
  }
  // dispatch(PutDriveredit(payload));
};


export const DeleteDriverAction = (payload: any): AppThunk => async (dispatch: any) => {
  dispatch(DeleteDriverapi(payload));
};


