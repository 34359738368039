import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userItem, userOtpState } from "../interface/userOtp_Interface";

import { AppState } from "../store";

const initialState: userOtpState = {
  userlist: [],
  totalRecords: 0
};

export const userOtpReducer = createSlice({ 
  name: "userotp",
  initialState, 
  reducers: {
    setUserOtpData: (
      state,
      action: PayloadAction<userItem[]>
    ) => ({
      ...state,
      userManagementDataObj: action.payload,
    }),
  },
});

export const {
    setUserOtpData
} = userOtpReducer.actions;

export const getUserManagementData = (state: AppState) => state.userManagement.userlist;

export { initialState as userotp };
export default userOtpReducer;