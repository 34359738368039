import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userlist, userlistitem} from "../interface/userlist_interface";
import { AppState } from "../store";

const initialState: userlist = {
  userlist:[],
    totalRecords: 0
};

export const userlistreducer = createSlice({
  name: "user list",
  initialState,
  reducers: {
    setuserlist: ( state, action: PayloadAction<userlistitem[]> ) => ({
      ...state,
      userlistitem: action.payload,
    }),
  },
});

export const { setuserlist  } = userlistreducer.actions;

export const getuserlist = (state: AppState) => state.userlistreducer.userlist;

export { initialState as userlist };
export default  userlistreducer;

