import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { fetchDropoffListData } from "../../Redux/fetchservice/drop-off-list";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { VpnKey } from "@mui/icons-material";
import { useNavigate } from "react-router";
import Img from "../../Assets/Images/seamless-background-laundry-37258906.jpg";
import { fetchDropoffListCodeData } from "../../Redux/fetchservice/drop-off-list-code";
import { LoaderBullet } from "../../components/loader";
import moment from "moment";
import "./index.css"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DropoffListAction } from "../../Redux/Action/drop-off-list_action";
import { getdropofflistdata } from "../../Redux/Reducer/drop-off-list_reducer";

export default function Dropofflist() {
  const dispatch = useDispatch();
  const dropoff = useSelector(getdropofflistdata);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [drop, setDrop] = useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = React.useState(false);
  const [data, setData] = useState(dropoff);
  const [dropoffcode, setDropoffcode] = useState("");
  const navigate = useNavigate();
  const [dataview, setDataview] = useState("");
  const [id, setId] = useState("");
  var userdata: any = window.sessionStorage.getItem("@userData");

  useEffect(() => {
    if (userdata) {
      userdata = JSON.parse(userdata);
      setId(userdata.id);
      fetchUsers(userdata.id);
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAlert(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const viewdetails = async (item: any) => {
    let payload = {};
    let response: any = await dispatch(DropoffListAction(payload));
    // console.log(data,"--->>>>data");
    if (data) {
      navigate("/view-details", { state: { data: data, code: item } });
    }
  };

  const submit = async () => {
    let payload = {
      laundry_id: id,
      code: dropoffcode,
    };

    let response: any = await fetchDropoffListCodeData(payload);
    if (response.data) {
      navigate("/Dropoffcode", { state: response });
      navigate('/dropoff-list')
      setOpen(false)
    } else {
      setAlert(true);
    }
  };

  const fetchUsers = async (LondryId: any) => {
    let payload = {
      id: LondryId,
      page: page,
      per_page: rowsPerPage,
    };

    let response: any = await fetchDropoffListData(payload);
    if (response.status === 404) {
      setMsg(true);
    }
    setData(response.data);
    setTotalRows(response.data?.count);
    setLoading(false);
  };


  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4"> Drop Off List</Typography>
        <Button variant="contained" onClick={handleClickOpen}>
          {" "}
          Accept New Drop Off
        </Button>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Drop Off</DialogTitle>
        <form>
          <DialogContent>
            <TextField
              type="number"
              onChange={(e: any) => setDropoffcode(e.target.value.replace(/(\D{6})/g, ""))}
              autoFocus

              margin="dense"
              id="name"
              label="Enter Drop Off Code"
              fullWidth
              placeholder="Enter Drop Off Code"
              sx={{ width: "500px" }}

              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                ),
              }}
            />
            {alert ? (
              <p className="error">Please Enter valid Drop Off Code!</p>
            ) : (
              <></>
            )}
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              style={{ justifyContent: "flex-end" }}
            >
              <Button onClick={submit} variant="contained">
                Submit
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                sx={{ marginLeft: "10px", marginRight: "17px" }}
              >
                Cancel
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <TableContainer
        component={Paper}
        // progressPending={loading}

        sx={{
          maxHeight: 557,
          minHeight: 557,
          bgcolor: "white",
          scrollBehavior: "none",
          overflow: "auto",
          width: "90%",
          marginLeft: "5%",
        }}
      >
        {msg ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: 560 }}
          >
            <Grid item xs={3}>
              <h1>Drop off List is empty. </h1>
            </Grid>
          </Grid>
        ) :
          loading ? (<div className="loader">

            <LoaderBullet />
          </div>
          ) : (
            <Table aria-label="customized table">
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: "0",
                    background: "#90caf9",
                    zIndex: "2",
                  }}
                >
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "capitalize",
                    }}
                  >
                    Drop off code
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "capitalize",
                    }}
                  >
                    Driver Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "none",
                    }}
                  >
                    {" "}
                    No. of bags
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "capitalize",
                    }}
                  >
                    Date & Time
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "capitalize",
                    }}
                  >
                    View Details
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: any) => (

                    <TableRow hover role="checkbox" sx={{ height: "50px" }}>
                      <TableCell component="th" scope="row">
                        {item.code}
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>{item.user ? item.user?.full_name : 'Self'}</TableCell>
                      <TableCell>
                        {" "}
                        {item.OrderDriver.map(
                          (o: any) => o.order.number_of_bags
                        ).reduce((p: any, c: any) => p + c, 0)}
                      </TableCell>
                      <TableCell>
                        {moment(item.created_at).format("MM-DD-YYYY")}{" "}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="info"
                          onClick={() => {
                            viewdetails(item.code);
                          }}
                        >
                          View Details
                        </Button>
                      </TableCell>
                      {/*  */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
      </TableContainer>
      <TablePagination
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
