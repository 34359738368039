import * as React from "react";
import { useState, useEffect } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FormControl, Grid, InputLabel, MenuItem, Select, TablePagination, Typography } from "@mui/material";
import { LoaderBullet } from "../../components/loader";
import { fetchmarkcleaned } from "../../Redux/fetchservice/mark_cleaned";
import moment from "moment";
import { useDispatch } from "react-redux";

const Orders = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState(false);
  const [status, setStatus] = useState("1");
  const [totalRecords, setTotalRecords] = useState(0);

  var userdata: any = window.sessionStorage.getItem("@userData");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    Orderlist();
  }, [page, status, rowsPerPage]);


  const Orderlist = async () => {
    setMsg(false)

    userdata = JSON.parse(userdata);
    let payload = {
      status: status,
      id: userdata.id,
      limit: rowsPerPage,
      offset: page * rowsPerPage
    }
    let response: any = await fetchmarkcleaned(payload);
    setLoading(false);
    if (response.status === 404) {
      setMsg(true)

    } else {
      setData(response.data);
      if (response?.data[0]?.totalCount) {
        setTotalRecords(response.data[0].totalCount);
      } else {
        setTotalRecords(0);
      }
    }
  }



  const handleStatus = (value: any) => {
    setStatus(value);
  }

  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4" color="black">
          {" "}
          Orders
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Select </InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={status}
            label="Select"
            onChange={(e) => handleStatus(e.target.value)}
          >
            <MenuItem value="1">Dropped Off</MenuItem>
            <MenuItem value="2">Tagged</MenuItem>
            <MenuItem value="3">Cleaned</MenuItem>
            <MenuItem value="4">Ready For Pickup</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <TableContainer
        component={Paper}
        // progressPending={loading}

        sx={{
          maxHeight: 557,
          minHeight: 557,
          bgcolor: "white",
          scrollBehavior: "none",
          overflow: "auto",
          width: "90%",
          marginLeft: "5%",
        }}
      >
        {msg ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: 560 }}
          >
            <Grid item xs={3}>
              <h1>No order details. </h1>
            </Grid>
          </Grid>
        ) : loading ? (
          <div className="loader">
            <LoaderBullet />
          </div>
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  position: "sticky",
                  top: "0",
                  background: "#90caf9",
                  zIndex: "2",
                }}
              >
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Order ID
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Bag ID
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "none",
                  }}
                >
                  {" "}
                  Customer Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Total Item
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Delivery Date
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((i: any, key) =>
                  <>
                    <TableRow hover role="checkbox" sx={{ height: "50px" }} key={key}>
                      <TableCell>

                        {i.order_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {i.tag_id}
                      </TableCell>
                      <TableCell>
                        {i?.full_name}
                      </TableCell>
                      <TableCell>
                        {i.quantity || 0}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(
                          i?.delivery_date
                        ).format("MM-DD-YYYY")}{" "}
                      </TableCell>
                      <TableCell>
                        {i.status
                          === "1" ? (
                          <p>Dropped Off</p>
                        ) : i.status === "2" ? (
                          <p>Tagged</p>
                        ) : i.status === "3" ? (
                          <p>Cleaned</p>
                        ) : i.status === "4" ? (
                          <p>Ready For Pickup</p>
                        ) : null}{" "}
                      </TableCell>
                    </TableRow>
                  </>)
              }
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        count={msg ? 0 : totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}
export default Orders;
