import * as React from "react";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import hidePwdImg from "../../Assets/Images/hide1.png";
import showPwdImg from "../../Assets/Images/show.png";
import "../Setting/Setting.css";


export default function ChangePwd() {
  const [pwd1, setPwd1] = React.useState("");
  const [isRevealPwd1, setIsRevealPwd1] = React.useState(false);
  const [pwd2, setPwd2] = React.useState("");
  const [isRevealPwd2, setIsRevealPwd2] = React.useState(false);
  const [pwd3, setPwd3] = React.useState("");
  const [isRevealPwd3, setIsRevealPwd3] = React.useState(false);

  
  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ padding: "10px" }}
      >
        <Typography variant="h4">Setting</Typography>
      </Grid>
      <Container maxWidth="sm">
    <TableContainer
      component={Paper}
      sx={{
        bgcolor: "white",
        width: "90%",
        marginLeft: "5%",
      }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow
            sx={{
              position: "sticky",
              top: "0",
              background: "#90caf9",
              zIndex: "2",
            }}
          >
            <TableCell sx={{ fontSize: "20px" }} align="left">
              Your Account
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
            >
            <Grid className="pwdIcon" sx={{ padding: "9px" }}>
              <TextField
                name="pwd"
                label="Old Password"
                placeholder="Enter Old Password"
                type={isRevealPwd1 ? "text" : "password"}
                value={pwd1}    
                
                variant="standard"
                fullWidth
                required
              />
                    
            </Grid>
              <Grid className="pwdIcon" sx={{ padding: "9px" }}>
                <TextField
                  type={isRevealPwd2 ? "text" : "password"}
                  value={pwd2}
                  onChange={(e) => setPwd2(e.target.value)}
                  label="New Password"
                  placeholder="Enter New Password "
                  variant="standard"
                  fullWidth
                  required
                />
                <img
                  title={isRevealPwd2 ? "Hide password" : "Show password"}
                  src={isRevealPwd2 ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd2((prevState) => !prevState)}
                />
              </Grid>
                 
              <Grid className="pwdIcon" sx={{ padding: "9px" }}>
                <TextField
                  type={isRevealPwd3 ? "text" : "password"}
                  value={pwd3}
                  onChange={(e) => setPwd3(e.target.value)}
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  variant="standard"
                  fullWidth
                  required
                />
                <img
                  title={isRevealPwd3 ? "Hide password" : "Show password"}
                  src={isRevealPwd3 ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd3((prevState) => !prevState)}
                />
              </Grid>
                </TableCell>
              </TableRow>
              <Grid sx={{ padding: "8px" }}>
                <Link to="/setting" style={{textDecoration:"none"}}>
                  <Button variant="contained" disableElevation>
                    Done
                  </Button>
                </Link>
              </Grid>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}