import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useradd, useradditem} from "../interface/userlist_interface";
 
import { AppState } from "../store";

const initialState: useradd = {
  useradd:[],
    totalRecords: 0
};

export const useraddreducer = createSlice({
  name: "user list",
  initialState,
  reducers: {
    setuseradd: ( state, action: PayloadAction<useradditem[]> ) => ({
      ...state,
      useradditem: action.payload,
    }),
  },
});

export const { setuseradd  } = useraddreducer.actions;

export const getuseradd = (state: AppState) => state.useraddreducer.useradd;

export { initialState as useradd };
export default  useraddreducer;

