import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userItem, userManagementDefaultState } from "../interface/userLoginInterface";

import { AppState } from "../store";

const initialState: userManagementDefaultState = {
  userlist: [],
  totalRecords: 0
};

export const userManagementReducer = createSlice({
  name: "userManagementState",
  initialState,
  reducers: {
    setUserManagementData: (
      state,
      action: PayloadAction<userItem[]>
    ) => ({
      ...state,
      userManagementDataObj: action.payload,
    }),
  },
});

export const {
  setUserManagementData
} = userManagementReducer.actions;

export const getUserManagementData = (state: AppState) => state.userManagement.userlist;

export { initialState as userManagementState };
export default userManagementReducer;



