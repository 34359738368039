import { fetchOtp } from "../fetchservice/UserManagement";
import { setUserOtpData } from "../Reducer/userOtpReducer";
import { AppThunk } from "../store";
import { setUserData } from "../../helper/Storage";
import { useEffect } from "react";
import swal from "sweetalert";

export const SetUserOtp =
  (payload: any, navigate: any): any =>
  async (dispatch: any) => {
    let request = {
      value: payload.email.value,
      code: payload.code.value,
    };

    const response: any = await fetchOtp(request);

    if (response != undefined && response.success) {
      let userObj: any = JSON.stringify(response.data);

      swal(response.message, "  ", "success");
      setUserData(userObj);
      dispatch(setUserOtpData(userObj));
      navigate("/reset-password", { state: response.data.user_id });
    } else {
      dispatch(setUserOtpData([]));
      swal(response.message, "  ", "error");
      if (response && response[0] && response[0].errorMessage) {
        let payload = {
          status: "error",
          message: response[0].errorMessage,
          display: true,
        };
      }
    }
  };
