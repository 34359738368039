import { Grid } from "@mui/material";
import "./index.css"

function Dashbord() {

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >

      <Grid item xs={3}>
        <h1>Under Development</h1>
      </Grid>
    </Grid>
  )

};
export default Dashbord