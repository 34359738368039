import { fetchUserManagementData } from "../fetchservice/UserManagement";
import { setUserManagementData } from "../Reducer/userLoginReducer";
import { AppThunk } from "../store";
import { setUserData, setToken } from "../../helper/Storage";
import { AnyAction } from "@reduxjs/toolkit";
import { Alert } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import swal from "sweetalert";

export const setUserManagementDataAction =
  (payload: any, navigate: any): any =>
  async (dispatch: any) => {
    let request = {
      value: payload.email.value,
      password: payload.password.value,
    };

    const response: any = await fetchUserManagementData(request);

    if (response != undefined && response.success) {
      let userObj: any = JSON.stringify(response.data);
      let token = response.data.token;
      setUserData(userObj);
      setToken(token);
      dispatch(setUserManagementData(userObj));
      // navigate('/dashbord');
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      dispatch(setUserManagementData([]));
      swal(response.message, "  ", "error");
      if (response && response[0] && response[0].errorMessage) {
        let payload = {
          status: "error",
          message: response[0].errorMessage,
          display: true,
        };
      }
    }
  };
