import { CheckOutlined, Close } from "@mui/icons-material";
import "./index.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { acceptrejectorder } from "../../Redux/fetchservice/acceptreject";
import swal from "sweetalert";
import moment from "moment";

const Dropoffcode = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [bags, setBags] = useState([]);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const [empty, setEmpty] = useState();
  const [alert, setAlert] = useState(false);
  const [filterdata1, setFilterdata1] = useState(false);

  const onClick = () => {
    navigate("/Dropoff-list");
  };

  const location = useLocation();
  const data: any = location.state;
  console.log(data);


  useEffect(() => {
    if (data.data[0].OrderDriver[0] === undefined) {
      setAlert(true);
    }
    setEmpty(data.data[0].OrderDriver[0]);
    setBags(data.data[0].OrderDriver);
    setCode(data.data[0].code);
  }, [filterdata1]);
  // useEffect(()=>{
  //   if (!data.data[0].OrderDriver.length) {
  //     swal("Orders not found"," ", "warning", {});
  //     console.warn("hello");
  //   } else {
  //     console.warn("byyy");
  //     setAlert(false);
  //   }
  // },[])
  const questionAlert = (id: any) => {
    swal("Are you sure?", " You want to accept this order!", "warning", {
      buttons: ["CANCEL", "ACCEPT"],
    }).then((okay) => {
      if (okay) {
        accept(id);
      }
    });
  };

  const questionAlert1 = (id: any) => {
    setFilterdata1(false)

    swal("Are you sure?", " You want to reject this order!", "warning", {
      buttons: ["CANCEL", "REJECT"],
    }).then((okay) => {
      if (okay) {
        reject(id);
      }
    });
  };
  const accept = async (id: any) => {
    setFilterdata1(false)

    let payload = {
      id: id,
      status: "5",
      code: code,
    };
    const response = await acceptrejectorder(payload);
    if (response.status === 200) {
      setFilterdata1(true)
    }
  };

  const reject = async (id: any) => {
    let payload = {
      id: id,
      status: "6",
    };
    await acceptrejectorder(payload);
    setFilterdata1(true)
  };

  const showTags = (dispatchTags: any) => {
    console.log('dispatchTags', dispatchTags);
    let tagData: any = "";
    dispatchTags.map((item: any) => {
      return tagData = tagData + "" + item.dispatchtag.tag_id + ",";
    })
    return (tagData);
  }
  return (
    <Grid sx={{ bgcolor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4">Accept Your Delivery :-</Typography>
        <Stack width="250px">
          <Typography>
            TOTAL BAG'S :{" "}
            {bags
              .map((o: any) => o.order.number_of_bags)
              .reduce((p: any, c: any) => p + c, 0)}{" "}
          </Typography>
          <Typography>Delivery Code: {code}</Typography>
        </Stack>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 560,
          minHeight: 560,
          bgcolor: "white",
          scrollBehavior: "none",
          overflow: "auto",
          width: "90%",
          marginLeft: "5%",
        }}
      >
        {" "}
        <Table>
          <TableHead>
            <TableRow
              sx={{
                position: "sticky",
                top: "0",
                background: "#90caf9",
                zIndex: "2",
              }}
            >
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "uppercase",
                }}
              >
                No.
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "uppercase",
                }}
              >
                Customer Name
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "uppercase",
                }}
              >
                Bag Tag's
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "uppercase",
                }}
              >
                Delivery Back to Customer Date
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "uppercase",
                }}
              >
                Action
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "uppercase",
                }}
              >
                Accept/Reject
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.map((item: any) => (
              <>
                {item.OrderDriver.map((itam: any, index: any) => (
                  <TableRow hover role="checkbox" sx={{ height: "50px" }}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{itam.order.userDetail.full_name}</TableCell>
                    <TableCell>{showTags(itam.order.orderdispatchtag)}</TableCell>
                    <TableCell>

                      <p> {moment(itam.order.delivery_date).format("YYYY-MM-DD")}</p>
                    </TableCell>
                    <TableCell><Button variant="outlined" disabled onClick={handleOpen}>Assign</Button></TableCell>

                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => {
                          questionAlert(itam.order.id);
                        }}
                      >
                        <CheckOutlined />
                      </Button>
                      <Button
                        sx={{ marginLeft: "10px" }}
                        variant="contained"
                        onClick={() => {
                          questionAlert1(itam.order.id);
                        }}
                      >
                        <Close />{" "}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid textAlign="center" margin="20px">
        <div>
          <Button onClick={handleOpen} variant="contained">
            Discard
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Are you sure you want to exit</DialogTitle>
            <DialogActions>
              <Button onClick={onClick}>Yes</Button>
              <Button onClick={handleClose} autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </Grid>
  );
};

export default Dropoffcode;
