import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Close,
  LaptopRounded,
  Mail,
  Phone,
  Title,
  Delete,
  Edit,
  Visibility,
  ExpandMore,
  ChevronRight,
} from "@mui/icons-material";
import User0 from "../../Assets/Images/user_image/user2.jpg";
import "./Index.css";
import { Dropdown, DropdownItem } from "muicss/react";
import {
  DataGrid,
  GridActionsCell,
  GridActionsCellItem,
  GridColDef,
  GridColumnApi,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { rolepermission } from "../../Redux/fetchservice/role-permission";
import {
  useradd,
  useredit,
  userlist,
  userremove,
} from "../../Redux/fetchservice/user";
import { TreeItem, TreeView } from "@mui/lab";
import Example from "./add_new_user";
import { LoaderBullet } from "../../components/loader";
import swal from "sweetalert";


const drawerWidth = "100%";
const User = () => {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = useState(false);
  const [user_list, setUser_list] = useState([]);
  const [drawerData, setDrawerData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [ipaddress, setIpaddress] = useState("");
  const [data, setData] = useState([]);
  const [checkbox, setCheckbox] = useState<String[]>([]);
  const [loading, setLoading] = useState(true);
  const [emailalert, setEmailalert] = useState(false);
  const [numberalert, setNumberalert] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);

  const Handleedit = (data: any) => {
    const name = data.name;
    setDrawerData(data);
  };

  const data1 = async () => {
    let payload = {};
    let response = await userlist(payload);
    setUser_list(response.data);
    setLoading(false);
  };

  const roledata = async () => {
    let payload = {};
    const response = await rolepermission(payload);
    setData(response.data);
  };

  useEffect(() => {
    data1();
    roledata();
  }, []);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const RemoveItem = async (item: any) => {
    let payload = {
      id: item,
    };
    const response = await userremove(payload);
  };
  const questionAlert = (id: any) => {
    swal("Are you sure?", " You want to delete user!", "warning", {
      buttons: ["CANCEL", "DELETE"],
      dangerMode: true,
    }).then((okay) => {
      if (okay) {
        RemoveItem(id);
        data1()
        swal("User is deleted", "  ", "success");
      }
    });
  };

  const adduseralert = () => {
    swal("Are you sure?", " You want to add user!", "warning", {
      buttons: ["CANCEL", "YES"],
      dangerMode: true,
    }).then((okay) => {
      if (okay) {
        Useradd();
      }
    });
  };
  const Useredit = async (item: any) => {
    let payload = {
      id: item,
      full_name: name,
      ip_address: ipaddress,
    };
    const response = await useredit(payload);
    handleClose1();
    data1()
  };

  const Useradd = async () => {
    let payload = {
      full_name: name,
      ip_address: ipaddress,
      mobile_number: phone,
      email: email,
      title: title,
      permission: checkbox,
    };
    const response = await useradd(payload);
    data1()
    if (response.status === 200) {
      swal("User is added", "  ", "success");
      handleClose()
    }
    else if (response.message === "Email address already exists.") {
      setEmailalert(true)
    } else if (response.message === "Mobile number already registered with us.") {
      setNumberalert(true)
    } else if (response.message === "This mobile & email already registered.") {
      setEmailalert(true)
      setNumberalert(true)
    }
  };

  const handleCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setCheckbox((prev: any[]) => [
        ...prev,
        { permission_id: e.target.value, role_id: e.target.id },
      ]);
    } else {
      setCheckbox(checkbox.filter((city) => city !== e.target.value));
    }
  };
  const getTreeItemsFromData = (treeItems: any) => {
    return treeItems.map((treeItemData: any) => {
      let children = treeItemData.permissions.map((i: any) => (
        <>
          <FormControlLabel
            sx={{ marginLeft: "30px" }}
            control={
              <Checkbox
                value={i.id}
                id={i.role_id}
                onChange={handleCheckboxChange}
              />
            }
            label={i.permission}
          />

          <br />
        </>
      ));
      return (
        <>
          <TreeItem
            key={treeItemData.id}
            nodeId={treeItemData.id}
            label={treeItemData.title}
            children={children}
          />
          <br />
        </>
      );
    });
  };
  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4">USER</Typography>
        <Button variant="contained" onClick={handleOpen}>
          ADD NEW USER
        </Button>
      </Grid>
      <Grid>
        <Drawer
          anchor="right"
          open={open1}
          onClose={handleClose}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
        >
          {user_list
            .filter((x: any) => x.id == drawerData)
            .map((Data: any) => (
              <Grid sx={{ bgcolor: "whitesmoke", paddingBottom: "60px" }}>
                <IconButton size="large" onClick={handleClose1}>
                  <Close fontSize="large" />
                </IconButton>
                <form
                  style={{
                    backgroundColor: "white",
                    paddingTop: "20px",
                    width: "60%",
                    marginLeft: "20%",
                  }}
                >
                  <Stack direction="row">
                    <Typography variant="h4" textAlign="center" width="100%">
                      EDIT USER DATA
                    </Typography>
                  </Stack>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TextField
                      id="input-with-icon-textfield"
                      label="Full Name"
                      defaultValue={Data.full_name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Avatar sx={{ width: "30px", height: "30px" }} />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      placeholder="Enter Full Name"
                      sx={{ width: "90%", margin: "10px 0" }}
                    />
                    <br />{" "}
                    <TextField
                      id="input-with-icon-textfield"
                      label="IP Address"
                      defaultValue={Data.EmployDetail.ip_address}
                      onChange={(e) => {
                        setIpaddress(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LaptopRounded />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      placeholder="Enter IP Address "
                      sx={{ width: "90%", margin: "10px 0  " }}
                    />
                    <br />
                  </Grid>
                  <Grid container direction="row" justifyContent="space-around">
                    <Button
                      variant="contained"
                      type="reset"
                      onClick={handleClose1}
                      sx={{
                        width: "200px",
                        margin: "20px 25px",
                        boxshadow: "3",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        Useredit(Data.id);
                      }}
                      sx={{
                        width: "200px",
                        margin: "20px 25px",
                        boxshadow: "3",
                      }}
                    >
                      save
                    </Button>
                  </Grid>
                </form>
              </Grid>
            ))}
        </Drawer>
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
        >
          <Grid sx={{ bgcolor: "whitesmoke", paddingBottom: "60px" }}>
            <IconButton size="large" onClick={handleClose}>
              <Close fontSize="large" />
            </IconButton>
            <form
              onSubmit={adduseralert}
              action="javascript:void(0)"
              style={{
                backgroundColor: "white",
                paddingTop: "20px",
                width: "60%",

                marginLeft: "20%",
              }}
            >
              <Stack direction="row">
                <Typography variant="h4" textAlign="center" width="100%">
                  ADD NEW USER
                </Typography>
              </Stack>
              <Grid
                container
                direction="column"
                justifyContent="center"

              >
                <TextField
                  required
                  id="input-with-icon-textfield"
                  label="Full Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Avatar sx={{ width: "30px", height: "30px" }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  placeholder="Enter Full Name"
                  sx={{ width: "90%", margin: "10px 5%" }}
                />

                <br />
                <TextField
                  required
                  id="input-with-icon-textfield"
                  label="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  placeholder="Enter Email"
                  sx={{ width: "90%", margin: "10px 5%  " }}
                />
                <Grid sx={{ width: "90%", margin: "0 5%  " }}>

                  {emailalert ? (<p className="error">Email address already exists.</p>) : (<></>)}
                </Grid>
                <br />
                <TextField
                  required
                  id="input-with-icon-textfield"
                  label="Title"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Title />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  placeholder="Enter Title"
                  sx={{ width: "90%", margin: "10px 5%  " }}
                />
                <br />
                <TextField
                  required
                  id="input-with-icon-textfield"
                  label="IP Address"
                  onChange={(e) => {
                    setIpaddress(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LaptopRounded />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  placeholder="Enter IP Address "
                  sx={{ width: "90%", margin: "10px 5%  " }}
                />
                <br />
                <TextField
                  required
                  id="input-with-icon-textfield"
                  label="Phone"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  placeholder="Enter Phone No."
                  sx={{ width: "90%", margin: "10px 5%  " }}
                />
                <Grid sx={{ width: "90%", margin: "0 5%  " }}>
                  {numberalert ? (<p className="error">Mobile number already registered with us.</p>) : (<></>)}
                </Grid>
                <br />
                <Grid sx={{ width: "90%", margin: "0 5%  ", boxShadow: "none" }}>
                  <Typography variant="h5" margin="10px 0">
                    {" "}
                    Permissions :-
                  </Typography>
                  <Paper>
                    <TreeView
                      defaultCollapseIcon={<ExpandMore />}
                      defaultExpandIcon={<ChevronRight />}
                    >
                      {getTreeItemsFromData(data)}
                    </TreeView>
                  </Paper>
                </Grid>
                <Grid container direction="row" justifyContent="space-around">
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={handleClose}
                    sx={{ width: "200px", margin: "20px 25px", boxshadow: "3" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: "200px", margin: "20px 25px", boxshadow: "3" }}
                  >
                    Add User
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Drawer>

        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 560,
            minHeight: 560,
            bgcolor: "white",
            scrollBehavior: "none",
            overflow: "auto",
            width: "90%",
            marginLeft: "5%",
          }}
        >
          {loading ? (
            <LoaderBullet />
          ) : (
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: "0",
                    background: "#90caf9",
                    zIndex: "2",
                  }}
                >
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "uppercase",
                    }}
                  >
                    ID
                  </TableCell>

                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "uppercase",
                    }}
                    key="name"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "uppercase",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "uppercase",
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "uppercase",
                    }}
                  >
                    IP Address
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "550",
                      textTransform: "uppercase",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user_list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any) => (
                    <TableRow hover role="checkbox" sx={{ height: "50px" }}>
                      <TableCell>{item.id}</TableCell>

                      <TableCell>{item.full_name}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.EmployDetail.title}</TableCell>
                      <TableCell>{item.EmployDetail.ip_address}</TableCell>

                      <TableCell>
                        <Dropdown label="Action" color="primary">
                          <DropdownItem>Active</DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              Handleedit(item.id);
                              handleOpen1();
                            }}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem onClick={() => questionAlert(item.id)}>
                            Remove
                          </DropdownItem>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={user_list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </div>
  );
};

export default User;
