import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Confirmandcreatestate, confirmandcreateItem } from "../interface/confirmandcreate_interface";

import { AppState } from "../store";

const initialState: Confirmandcreatestate = {
    confirmandcreate:[],
    totalRecords: 0
};

export const confirmandcreatereducer = createSlice({
  name: "loundryState",
  initialState,
  reducers: {
    setconfirmandcreate: ( state, action: PayloadAction<confirmandcreateItem[]> ) => ({
      ...state,
      confirmandcreateItem: action.payload,
    }),
  },
});

export const { setconfirmandcreate } = confirmandcreatereducer.actions;

export const getconfirmandcreate = (state: AppState) => state.createandconfirmreducer.confirmandcreate;

export { initialState as loundryState };
export default confirmandcreatereducer;
