import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";

export const fetchDriverlist = async (payload: any) => {
    console.log('payload',ApiEndPoints.Driver_list+payload.id);
    
    return await ApiServices('get', payload, ApiEndPoints.Driver_list+payload.id); 
       
};

export const PostDriveradd = async (payload: any) => {
    const data = await ApiServices('post', payload, ApiEndPoints.Driver_add);
    return data;  
};

export const PutDriveredit = async (payload: any) => {
    const data = await ApiServices('put', payload, ApiEndPoints.Driver_edit);
    return data;
};

export const DeleteDriverapi = async (payload: any) => {
  await ApiServices('delete', payload, ApiEndPoints.Driver_delete);
    
};
