import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Grid,
  IconButton,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { Markcleanedactoin } from "../../Redux/Action/mark-cleaned_action";
import { fetchmarkcleaned } from "../../Redux/fetchservice/mark_cleaned";
import { LoaderBullet } from "../../components/loader";
import swal from "sweetalert";
import moment from "moment";
import "./mark_cleaned.css";
import { Clear, Search } from "@mui/icons-material";
import { fetchconfirm_tag } from "../../Redux/fetchservice/confirm_tag";

export default function Mark_cleaned() {
  const [searchedVal, setSearchedVal] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [msg, setMsg] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);

  var userdata: any = window.sessionStorage.getItem("@userData");
  const userdatas = JSON.parse(userdata);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const Markcleaned = async () => {
    let payload = {
      status: "2",
      id: userdatas.id,

    };
    const response = await fetchmarkcleaned(payload);
    if (response.status === 404) {
      setMsg(true);
    }
    setData(response.data);
    if (response?.data[0]?.totalCount) {
      setTotalRecords(response.data[0].totalCount);
    } else {
      setTotalRecords(0);
    }
    response.data ? setLoading(false) : setLoading(true);
  };

  const markcleaned = async (id: any) => {
    let payload = {
      id: id,
      status: "3",
    };
    const response = await fetchconfirm_tag(payload);
    Markcleaned()
  };

  React.useEffect(() => {
    Markcleaned();
  }, []);

  const Alert = (item: any) => {


    swal("Are you sure?", " The clothes have been cleaned?", "warning", {
      buttons: ["CANCEL", "ACCEPT"],
      dangerMode: true,
    }).then((okay) => {
      if (okay) {
        markcleaned(item);

      }
    });
  };


  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4" color="black">
          {" "}
          Mark Cleaned
        </Typography>
        <Grid>
          <TextField
            label="Enter Bag Id "
            sx={{ height: " 1em !implements" }}
            size="small"
            value={searchedVal}
            onChange={(e: any) => setSearchedVal(e.target.value)}
            InputProps={{
              endAdornment: searchedVal ? (
                <IconButton onClick={(e: any) => setSearchedVal("")}>
                  <Clear />
                </IconButton>
              ) : (
                <IconButton>
                  <Search />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 560,
          minHeight: 560,
          bgcolor: "white",
          scrollBehavior: "none",
          overflow: "auto",
          width: "90%",
          marginLeft: "5%",
        }}
      >
        {totalRecords === 0 ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: 560 }}
          >
            <Grid item xs={3}>
              <h1>Mark clean is empty. </h1>
            </Grid>
          </Grid>
        ) : loading ? (
          <div className="loader">
            <LoaderBullet />
          </div>
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  position: "sticky",
                  top: "0",
                  background: "#90caf9",
                  zIndex: "2",
                }}
              >
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Order ID
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Bag ID
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Customer Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Total Items
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Delivery Date
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data
                .filter(
                  (item: any) =>
                    !searchedVal.length ||
                    item.order_id
                      .toString()
                      .toLowerCase()
                      .includes(searchedVal.toString().toLowerCase()) ||
                    item.tag_id
                      .toString()
                      .toLowerCase()
                      .includes(searchedVal.toString().toLowerCase())
                )

                .map((i: any, key) =>
                  <TableRow hover role="checkbox" sx={{ height: "50px" }} key={key}>
                    <TableCell>

                      {i.order_id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {i.tag_id}
                    </TableCell>
                    <TableCell>
                      {i?.full_name}
                    </TableCell>
                    <TableCell>
                      {i.quantity || 0}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {moment(
                        i?.delivery_date
                      ).format("MM-DD-YYYY")}{" "}
                    </TableCell>
                    <TableCell>
                      {i.status === "2" ? <p>Tagged</p> : null}{" "}
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="contained"
                        disableElevation
                        onClick={() => Alert(i.dispatch_tag_id)}
                      >
                        mark cleaned
                      </Button>
                    </TableCell>
                  </TableRow>

                )
              }
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
   
    </div>
  );
}
