import { AppThunk } from "../store";
import { setdropofflistdata } from "../Reducer/drop-off-list_reducer";
import { fetchDropoffListData } from "../fetchservice/drop-off-list";
 import { acceptrejectorder } from "../fetchservice/acceptreject";
 
export const DropoffListAction = (payload: any): any => async (dispatch: any) => {
  
  const response: any = await fetchDropoffListData(payload);
  if (response.data) {
    dispatch(setdropofflistdata(response.data));
    
  }
};
 


export const AcceptrejectAction = (payload: any): AppThunk => async (dispatch: any) => {
  
  const response: any = await acceptrejectorder(payload);
  
};


