import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import {
  Close,
  Mail,
  Phone,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { fetchDriverlist, PutDriveredit, DeleteDriverapi } from "../../Redux/fetchservice/fetch_Driver";
import { getDriverListData } from "../../Redux/Reducer/Driver_reducer";
import { AddDriverAction } from "../../Redux/Action/Driver_action";
import { LoaderBullet } from "../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownItem } from "muicss/react";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import "../Drivers/Driver.css"

const drawerWidth = "100%";

const Drivers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const driverlist = useSelector(getDriverListData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [msg, setMsg] = React.useState(false);
  const [driverlistdata, setDriverlistdata] = React.useState<String[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const open = Boolean(anchorEl);
  const [opend, setOpend] = React.useState(false);
  const [full_name, setFull_name] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [password, setPassword] = React.useState("");
  const [showpassword, setShowpassword] = React.useState(false);
  const [mobile_number, setMobile_number] = React.useState("");
  const handleOpen = () => setOpend(true);
  const handleClosed = () => setOpend(false);
  const [open1, setOpen1] = React.useState(false);
  const [drawerData, setDrawerData] = React.useState();
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [error, setError] = React.useState<any>([])




  const AddDriver = async (page = 1) => {
    const e: any = [];

    if (!full_name) {
      e['full_name'] = "Please enter name"
    }
    if (!email) {
      e['email'] = "Please enter email"
    }
    if (!mobile_number) {
      e['mobile_number'] = "Please enter mobile number"
    }
    if (!password) {
      e['password'] = "Please enter password"
    }
    else {

      let payload = {
        full_name: full_name,
        email: email,
        password: password,
        mobile_number: mobile_number,
        laundry: userdatas.id,
      };


      const response = await dispatch(AddDriverAction(payload, navigate));
    }
    setError(e);
  };

  const adduseralert = () => {
    swal("Are you sure?", " You want to add user!", "warning", {
      buttons: ["CANCEL", "YES"],
      dangerMode: true,
    }).then((okay) => {
      if (okay) {
        AddDriver();
      }
    });
  };

  const Handleedit = (data: any) => {

    const name = data.name;
    setDrawerData(data);
  };
  const DriverEdit = async (item: any) => {
    let payload = {
      full_name: full_name,
      email: email,
      password: password,
      mobile_number: mobile_number,
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response = await PutDriveredit(payload);


  };

  const fetchData = async (page = 1) => {

    let payload = {
      id: userdatas.id
    };
    const response = await fetchDriverlist(payload);
    setDriverlistdata(response.data);
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  const RemoveItem = async (item: any) => {
    let payload = {
      id: item,
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await DeleteDriverapi(payload);
  };
  const questionAlert = (id: any) => {
    swal("Are you sure?", " You want to delete user!", "warning", {
      buttons: ["CANCEL", "DELETE"],
      dangerMode: true,
    }).then((okay) => {
      if (okay) {
        RemoveItem(id);
        fetchData()
        swal("User is deleted", "  ", "success");
      }
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  var userdata: any = window.sessionStorage.getItem("@userData");
  const userdatas = JSON.parse(userdata);


  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4">Driver</Typography>
        {/* <Button variant="contained" onClick={handleOpen}>
          ADD DRIVER
        </Button> */}
      </Grid>
      <Drawer
        anchor="right"
        open={open1}
        onClose={handleClose}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        {driverlistdata ? driverlistdata
          .filter((x: any) => x.id === drawerData)
          .map((Data: any) => (
            <Grid sx={{ bgcolor: "whitesmoke", paddingBottom: "60px" }}>
              <IconButton size="large" onClick={handleClose1}>
                <Close fontSize="large" />
              </IconButton>
              <form
                style={{
                  backgroundColor: "white",
                  paddingTop: "20px",
                  width: "60%",
                  marginLeft: "20%",
                }}
              >

                <Stack direction="row">
                  <Typography variant="h4" textAlign="center" width="100%">
                    EDIT DRIVER
                  </Typography>
                </Stack>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <br />
                  <TextField
                    // 
                    id="input-with-icon-textfield"
                    label="Full Name"
                    defaultValue={Data.driverInfo.full_name}
                    onChange={(e: any) => {
                      setFull_name(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Avatar />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    placeholder="Enter Full Name"
                    sx={{ width: "90%", margin: "10px 5%" }}
                  />

                  <br />
                  <TextField

                    id="input-with-icon-textfield"
                    label="Email"
                    defaultValue={Data.driverInfo.email}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    placeholder="Enter Email"
                    sx={{ width: "90%", margin: "10px 5%  " }}
                  />
                  <Grid sx={{ width: "90%", margin: "0 5%  " }}>
                  
                  </Grid>
                  <br />
                  <TextField

                    id="input-with-icon-textfield"
                    label="Mobile No."
                    defaultValue={Data.driverInfo.mobile_number}
                    onChange={(e: any) => {
                      setMobile_number(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    placeholder="Enter Mobile No."
                    sx={{ width: "90%", margin: "10px 5%  " }}
                  />
                  <br />

                  <TextField
                    type={showpassword ? "text" : "password"}

                    id="input-with-icon-textfield"
                    label="Password"
                    defaultValue={Data.driverInfo.password}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          onClick={() => setShowpassword((prevState) => !prevState)}
                        >
                          {showpassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                    variant="outlined"
                    placeholder="Enter Password "
                    sx={{ width: "90%", margin: "10px 5%  " }}
                  />
                  <br />
                  <Grid sx={{ width: "90%", margin: "0 5%  " }}>
                 
                  </Grid>
                  <br />

                  <Grid container direction="row" justifyContent="space-around">
                    <Button
                      variant="contained"
                      type="reset"
                      onClick={handleClose1}
                      sx={{ width: "200px", margin: "20px 25px", boxshadow: "3" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        DriverEdit(Data.id);
                      }}
                      sx={{ width: "200px", margin: "20px 25px", boxshadow: "3" }}
                    >
                      save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )) : null}
      </Drawer>
      <Drawer
        anchor="right"
        open={opend}
        onClose={handleClose}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        <Grid sx={{ bgcolor: "whitesmoke", paddingBottom: "60px" }}>
          <IconButton size="large" onClick={handleClosed}>
            <Close fontSize="large" />
          </IconButton>
          <form
            onSubmit={adduseralert}
            // eslint-disable-next-line no-script-url
            action="javascript:void(0)"
            style={{
              backgroundColor: "white",
              paddingTop: "20px",
              width: "60%",

              marginLeft: "20%",
            }}
          >
            <Stack direction="row">
              <Typography variant="h4" textAlign="center" width="100%">
                ADD NEW DRIVER
              </Typography>
            </Stack>
            <Grid container direction="column" justifyContent="center">
              <br />
              <TextField
                value={full_name}
                id="input-with-icon-textfield"
                label="Full Name"
                onChange={(e: any) => {
                  setFull_name(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Avatar />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="Enter Full Name"
                sx={{ width: "90%", margin: "10px 5%" }}
              />
              {error.full_name && <div className="error-msg">{error.full_name}</div>}
              <br />
              <TextField
                value={email}
                id="input-with-icon-textfield"
                label="Email"
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="Enter Email"
                sx={{ width: "90%", margin: "10px 5%  " }}
              />
              <Grid sx={{ width: "90%", margin: "0 5%  " }}>
              </Grid>
              {error.email && <div className="error-msg">{error.email}</div>}
              <br />
              <TextField
                value={mobile_number}
                id="input-with-icon-textfield"
                label="Mobile No."
                onChange={(e: any) => {
                  setMobile_number(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="Enter Mobile No."
                sx={{ width: "90%", margin: "10px 5%  " }}
              />
              {error.mobile_number && <div className="error-msg">{error.mobile_number}</div>}
              <br />
              <TextField
                type={showpassword ? "text" : "password"}
                value={password}
                id="input-with-icon-textfield"
                label="Password"
                onChange={(e: any) => {
                  setPassword(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      onClick={() => setShowpassword((prevState) => !prevState)}
                    >
                      {showpassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                variant="outlined"
                placeholder="Enter IP Password "
                sx={{ width: "90%", margin: "10px 5%  " }}
              />
              {error.password && <div className="error-msg">{error.password}</div>}
              <br />
              <Grid sx={{ width: "90%", margin: "0 5%  " }}>

              </Grid>
              <br />
              <Grid container direction="row" justifyContent="space-around">
                <Button
                  variant="contained"
                  type="reset"
                  onClick={handleClosed}
                  sx={{ width: "200px", margin: "20px 25px", boxshadow: "3" }}
                >
                  Cancel
                </Button>
                {/* <SubmitButton /> */}
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ width: "200px", margin: "20px 25px", boxshadow: "3" }}
                >
                  Add DRIVER
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Drawer>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 557,
          minHeight: 557,
          bgcolor: "white",
          scrollBehavior: "none",
          overflow: "auto",
          width: "90%",
          marginLeft: "5%",
        }}
      >
        {msg ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: 560 }}
          >
            <Grid item xs={3}>
              <h1>No order details. </h1>
            </Grid>
          </Grid>
        ) : loading ? (
          <div className="loader">
            <LoaderBullet />
          </div>
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  position: "sticky",
                  top: "0",
                  background: "#90caf9",
                  zIndex: "2",
                }}
              >
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Driver Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "none",
                  }}
                >
                  {" "}
                  Mobile No.
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  E-Mail
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {driverlistdata && driverlistdata.map((item: any, index) => (
                <TableRow hover role="checkbox" sx={{ height: "50px" }}>
                  {" "}
                  <TableCell>{index + 1}</TableCell>{" "}
                  <TableCell>{item.driverInfo?.full_name}</TableCell>{" "}
                  <TableCell>{item.driverInfo?.mobile_number}</TableCell>{" "}
                  <TableCell>{item.driverInfo?.email}</TableCell>{" "}
                  <TableCell>{item.driverInfo?.status === "1" ? <>Active</> : <>Inactive</>}</TableCell>
                  <TableCell>
                    <Dropdown label="Action" variant="contained" color="primary">
                      <DropdownItem
                        onClick={() => {
                          Handleedit(item.id);
                          handleOpen1();
                        }}
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem onClick={() => questionAlert(item.id)}>
                        Remove
                      </DropdownItem>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={driverlistdata && driverlistdata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
export default Drivers;
