import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, TablePagination, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { CheckOutlined, Close } from "@mui/icons-material";
import { acceptrejectorder } from "../../Redux/fetchservice/acceptreject";
import { AssignTagToCustomer, TagNotAllocate } from "../../Redux/fetchservice/Tags_not_allocate";
// import { AssignTagToCustomerAction } from "../../Redux/Action/Tag_not_Allocate_Action";
import Select from "@mui/material/Select"
import swal from "sweetalert";
import { LoaderBullet } from "../../components/loader";
import { getdropofflistdata } from "../../Redux/Reducer/drop-off-list_reducer";
import { useSelector } from "react-redux";
import { fetchDropoffListData } from "../../Redux/fetchservice/drop-off-list";

function createData(
  Order_Id: number,
  No_Of_Bags: number,
  Customer_Name: string,
  Pickup_Date: any,
  Delivery_Date: any,
  Bag_Tags: number
) {
  return {
    Order_Id,
    No_Of_Bags,
    Customer_Name,
    Pickup_Date,
    Delivery_Date,
    Bag_Tags,
  };
}

export default function Viewdetail() {
  const dropoff = useSelector(getdropofflistdata);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const location = useLocation();
  const code: any = location.state;
  const data = code.data;
  const [open, setOpen] = React.useState(false);
  const [alloc, setAlloc] = useState([]);
  const [tagId, setTagId] = useState("");
  const [assignId, setAssignId] = useState("");
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState(false);
  const [filterdata, setFilterdata] = useState([]);
  const [filterdata1, setFilterdata1] = useState(false);
  
  var userdata: any = window.sessionStorage.getItem("@userData");

  useEffect(() => {
    if (userdata) {
      userdata = JSON.parse(userdata);
      fetchUsers(userdata.id);
    }
  }, [filterdata1]);


  const fetchUsers = async (LondryId: any) => {
    let payload = {
      id: LondryId,
      page: page,
      per_page: rowsPerPage,
    };

    let response: any = await fetchDropoffListData(payload);
    if (response.status === 404) {
      setMsg(true);
    }
    setFilterdata(response.data)

  };

  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tagNotAllocate = async () => {
    let payload = {
      category_id: 12,
    };
    const response = await TagNotAllocate(payload);
    setAlloc(response.data);

    response.data ? setLoading(false) : setLoading(true);
    if (response.status === 400) {
      setMsg(true);
    }
  };

  useEffect(() => {
    tagNotAllocate();
  }, []);

  const assignTagToCustomer = async () => {

    let payload = {
      id: tagId,
      tag_id: assignId,
    };

    const response = await AssignTagToCustomer(payload);
    handleClose();
    setTagId("")
    setAssignId("")
    if (response.status === 200) {
      setFilterdata1(true)
    }
  }

  const questionAlert1 = (id: any) => {
    swal("Are you sure?", " You want to reject this order!", "warning", {
      buttons: ["CANCEL", "REJECT"],
    }).then((okay) => {
      if (okay) {
        reject(id);


      }
    });
  };
  const accept = async (id: any) => {
    setFilterdata1(false)
    let payload = {
      id: id,
      status: "5",
      code: code.code,
    };
    const response = await acceptrejectorder(payload);
    if (response.status === 200) {
      setFilterdata1(true)
    }
  };

  const reject = async (id: any) => {
    setFilterdata1(false)
    let payload = {
      id: id,
      status: "6",
    };
    await acceptrejectorder(payload);
    setFilterdata1(true)
  };

  const handleAssignBag = async (id: string) => {
    setAssignId(id)
  }
  const questionAlert = (id: any) => {
    swal("Are you sure?", " You want to accept this order!", "warning", {
      buttons: ["CANCEL", "ACCEPT"],
    }).then((okay) => {
      if (okay) {
        accept(id.id);

      }
    });
  };
  const showTags = (dispatchTags: any) => {
    const length = dispatchTags.length
    
    var tagData: any = "";
    
    dispatchTags.map((item: any) => {

      return tagData = tagData + " " + item.dispatchtag.tag_id + (length > 1 ? (",") : (""));

    })
    return (tagData);
    
  }



  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ width: "90%", margin: "0 5%", padding: "10px 0" }}
      >
        <Typography variant="h4" color="black">
          {" "}
          Details{" "}
        </Typography>
        <Link to="/dropoff-list">
          <Button variant="contained">back</Button>
        </Link>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 560,
          minHeight: 560,
          bgcolor: "white",
          scrollBehavior: "none",
          overflow: "auto",
          width: "90%",
          marginLeft: "5%",
        }}
      >{msg ? (
        <></>
      ) : loading ? (
        <div className="loader">
          <LoaderBullet />
        </div>
      ) : (
        <Table aria-label="customized table">

          <TableHead>
            <TableRow
              sx={{
                position: "sticky",
                top: "0",
                background: "#90caf9",
                zIndex: "2",
              }}
            >
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Order ID
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "none",
                }}
              >
                No. of bags
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Customer Name
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Pickup Date
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Delivery Date
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Bag tags
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Action
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "550",
                  textTransform: "capitalize",
                }}
              >
                Accept/Reject
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterdata
              .filter((x: any) => x.code === code.code)
              .map((v: any) => (
                <>
                  {v.OrderDriver.map((item: any) => (
                    <TableRow hover role="checkbox" sx={{ height: "50px" }}>
                      <TableCell>{item.order.id}</TableCell>
                      <TableCell>{item.order.number_of_bags}</TableCell>
                      <TableCell>
                        {
                          item?.order?.orderdispatchtag[0]?.dispatchtag?.user?.full_name
                        }
                      </TableCell>
                      <TableCell>
                        {item.order.delivery_date == null ? (<div>- - - - - -</div>) : (moment(item.order.pickup_date).format("YYYY-MM-DD"))}
                      </TableCell>
                      <TableCell>{item.order.delivery_date == null ? (<div>- - - - - - </div>) : (moment(item.order.delivery_date).format("YYYY-MM-DD"))}
                      </TableCell>
                      <TableCell>{showTags(item.order.orderdispatchtag)}</TableCell>
                      <TableCell>
                        {(item.order.status === '0') ? <p>Pending</p> : (item.order.status === '1') ? <p>Pickup by driver</p> : (item.order.status === '2') ? <p>Accept</p> :
                          (item.order.status === '3') ? <p>Draft</p> : (item.order.status === '4') ? <p>Complete order</p> : (item.order.status === '5') ? <p>Order accepted by laundry</p> :
                            (item.order.status === '6') ? <p>Rejected</p> : 'Delivery back to customer'}
                      </TableCell>
                      <TableCell>{item.order.orderdispatchtag.some((codes: any) => {
                        return codes.dispatchtag.tag_id === null;
                      })
                        ?
                        <Button variant="outlined" onClick={handleOpen}>Assign</Button>
                        :
                        <Button variant="outlined" disabled onClick={handleOpen}>Assign</Button>}
                        <div>
                          <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <Grid>
                              <Grid style={{ float: "right" }}>
                                <IconButton onClick={handleClose}><Close /></IconButton>
                              </Grid>
                              <DialogTitle id="alert-dialog-title">
                                Select Tag
                              </DialogTitle >
                              <DialogContent id="alert-dialog-description">
                                <FormControl sx={{ m: 1, width: 250 }}>
                                  <InputLabel id="demo-select-small">Select Order Dispatch Tag Id</InputLabel>
                                  <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={tagId}
                                    defaultValue=""
                                    label="Select Order dispatch tag id"
                                    onChange={(e) => setTagId(e.target.value)}
                                  >
                                    {item.order.orderdispatchtag.map((val: any, i: any) => (

                                      <MenuItem value={val.dispatchtag.id} key={i}>{val.dispatchtag.label}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 250 }}>
                                  <InputLabel id="demo-select-small">Select Tag</InputLabel>
                                  <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={assignId}
                                    defaultValue=""
                                    label="Select Tag"
                                    onChange={(e) => handleAssignBag(e.target.value)}
                                  >
                                    {alloc.map((vol: any, i: any) => (
                                      vol.tag.map((va: any, i: any) => (
                                        // <>

                                        <MenuItem value={va.id} key={i}>{va.id}</MenuItem>
                                        // </>
                                      ))
                                    ))}


                                  </Select>
                                </FormControl>
                              </DialogContent>
                              <DialogActions>
                                <Button variant="contained" disabled={!assignId || !tagId} onClick={assignTagToCustomer}>Done</Button>

                              </DialogActions>
                            </Grid>
                          </Dialog>
                        </div>
                      </TableCell>
                      {/* ))} */}
                      <TableCell>
                        {(item.order.status === '2') ? (<>
                          <Button
                            variant="contained"
                            onClick={() => {
                              questionAlert(item.order);
                            }}
                          >
                            <CheckOutlined />
                          </Button>
                          <Button

                            sx={{ marginLeft: "10px" }}
                            variant="contained"
                            onClick={() => {
                              questionAlert1(item.order.id);
                            }}
                          >
                            <Close />{" "}
                          </Button></>) : (<>
                            <Button
                              variant="contained" disabled
                              onClick={() => {
                                questionAlert(item.order.id);
                              }}
                            >
                              <CheckOutlined />
                            </Button>
                            <Button
                              sx={{ marginLeft: "10px" }}
                              variant="contained" disabled
                              onClick={() => {
                                questionAlert1(item.order.id);
                              }}
                            >
                              <Close />{" "}
                            </Button></>)}
                        {item.order.status === '6' ?
                          <Button
                            sx={{ marginLeft: "10px" }}
                            variant="contained"
                            onClick={() => {
                              questionAlert(item.order);
                            }}
                          >
                            Restore
                          </Button> :
                          null}

                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ))}
          </TableBody>
        </Table>
      )}
      </TableContainer>
      <TablePagination
        component="div"
        count={data.filter((x: any) => x.code === code.code).map((val: any) => val.OrderDriver.length)}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
