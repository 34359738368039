import { Javascript } from "@mui/icons-material";
import { Alert, Button, Container, Dialog, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import TextInput from "../../../common/textInput";
import { OnChangeModel } from "../../../common/types/Formtypes";
import { SetUserResetPasswordData } from "../../../Redux/Action/UserResetPasswordAction";
import "../../Login/Login.css";


function Setnewpassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password1, setPassword1] = useState("");
  const [password1Error, setPassword1Error] = useState("");

  const location = useLocation();
  const data: any = location.state;

 
  const [formState, setFormState] = useState({
    user_id: { error: "", value: data },
    password: { error: "", value: "" },
    password1: { error: "", value: "" },
  });

  const ChangePassword = (model: OnChangeModel, e: any): void => {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });

    setPasswordError("");
    setPassword(e.target.value);
  };
  const ConfirmPassword = (model: OnChangeModel, e: any): void => {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });

    setPassword1Error("");
    setPassword1(e.target.value);
  };
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    if (formState.password.value.length == 0) {
      setPasswordError("Please enter password");
      return false;
    }
    if (formState.password1.value.length == 0) {
      setPassword1Error("Please enter confirm password");
      return false;
    }
    if (formState.password.value !== formState.password1.value) {
      setPassword1Error("Your password does not match");
     
      return false;
    }
    if (formState.password.value == formState.password1.value) {   
     dispatch(SetUserResetPasswordData(formState, navigate));
      return false;
    }
  };
  
  return (
    <Dialog
      fullWidth
      open={true}
      maxWidth="xs"
      sx={{
        background:
          "url(https://www.rd.com/wp-content/uploads/2021/09/GettyImages-1181334518-MLedit.jpg)",
        backdropFilter: "blur(10px)",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <form onSubmit={handleFormSubmit} action="Javascript:vaod(0)">
          <Grid>
            <Grid textAlign="center">
              <h1> Set New Password</h1>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly "
              height="250px"
              border="1"
            >
              <Grid>
                <TextInput
                  id="input_password"
                  field="password"
                  value={formState.password.value}
                  onChange={ChangePassword}
                  required={true}
                  maxLength={100}
                  label="New Password"
                  placeholder="New Password"
                />
                {passwordError && (
                  <div className="error-msg">{passwordError}</div>
                )}
              </Grid>
              <Grid>
                <TextInput
                  id="input_password"
                  field="password1"
                  value={formState.password1.value}
                  onChange={ConfirmPassword}
                  required={true}
                  maxLength={100}
                  label="Confirm Password"
                  placeholder="Confirm Password"
                />
                {password1Error && (
                  <div className="error-msg">{password1Error}</div>
                )}
              </Grid>
              <Button
                // onClick={notify}
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                size="large"
              >
                {" "}
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
}
export default Setnewpassword;

// import React from 'react';

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function App(){
//   const notify = () => toast("Wow so easy!");

//   return (
//     <div>
//       <button onClick={notify}>Notify!</button>
//       <ToastContainer />
//     </div>
//   );
// }
// export default App
