import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {   userremove, userremoveitem} from "../interface/userlist_interface";
import { AppState } from "../store";

const initialState: userremove = {
  userremove:[],
    totalRecords: 0
};

export const userremovereducer = createSlice({
  name: "user list",
  initialState,
  reducers: {
    setuserremove: ( state, action: PayloadAction<userremoveitem[]> ) => ({
      ...state,
      userlistitem: action.payload,
    }),
  },
});

export const { setuserremove  } = userremovereducer.actions;

export const getuserremove = (state: AppState) => state.userremovereducer.userremove;

export { initialState as userremove };
export default  userremovereducer;

