import { useredit,useredititem } from "../interface/userlist_interface";
import { AppState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState: useredit = {
    useredit:[],
      totalRecords: 0
  };
  
  export const usereditreducer = createSlice({
    name: "user list",
    initialState,
    reducers: {
      setuseredit: ( state, action: PayloadAction<useredititem[]> ) => ({
        ...state,
        useredititem: action.payload,
      }),
    },
  });
  
  export const { setuseredit  } = usereditreducer.actions;
  
  export const getuseredit = (state: AppState) => state.usereditreducer.useredit;
  
  export { initialState as useredit };
  export default usereditreducer;
  