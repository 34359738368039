import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Dropofflistcodestate,
  dropofflistcodeItem,
} from "../interface/drop-off-list_interface";
import { AppState } from "../store";

const initialState: Dropofflistcodestate = {
    dropofflistcode: [],
  totalRecords: 0,
};

export const dropoffliscodetreducer= createSlice({
  name: "loundryCodeState",
  initialState,
  reducers: {
    setdropofflistcodedata: (
      state,
      action: PayloadAction<dropofflistcodeItem[]>
    ) => ({
      ...state,
      dropofflistcodeItem: action.payload,
    }),
  },
});

export const { setdropofflistcodedata } = dropoffliscodetreducer.actions;

export const getdropofflistcodedata = (state: AppState) =>
  state.dropofflistcodereducer.dropofflistcode;

export { initialState as loundryCodeState };
export default dropoffliscodetreducer;
