import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userItem, userResetPasswordState } from "../interface/userResetPassword_Interface";

import { AppState } from "../store";

const initialState: userResetPasswordState = {
  userlist: [],
  totalRecords: 0
};

export const userResetPasswordReducer = createSlice({
  name: "userResetState",
  initialState,
  reducers: {
    setUserResetData: (
      state,
      action: PayloadAction<userItem[]>
    ) => ({
      ...state,
      userManagementDataObj: action.payload,
    }),
  },
});

export const {
    setUserResetData
} = userResetPasswordReducer.actions;

export const getUserManagementData = (state: AppState) => state.userManagement.userlist;

export { initialState as userResetState };
export default userResetPasswordReducer;
