import {
  Alert,
  Button,
  Dialog,
  Container,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import TextInput from "../../../common/textInput";
import { OnChangeModel } from "../../../common/types/Formtypes";
import { SetUserOtp } from "../../../Redux/Action/UserOtpAction"; 
import "../../Login/Login.css";

function Verifyopt() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const location = useLocation();
const data:any=location.state

const [formState, setFormState] = useState({
    email: { error: "", value:data.value},
    code: { error: "", value: "" },
  });

 
 const hasFormValueChanged = (model: OnChangeModel,e: any ): void => {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });
    setCodeError("");
    setCode(e.target.value);
  };
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    if (formState.code.value.length == 0) {
      setCodeError("Please enter the otp");
      return false;
    }
   
    dispatch(SetUserOtp(formState,navigate));
  };

  return (
    <Dialog
      fullWidth
      open={true}
      maxWidth="xs"
      sx={{
        background:
          "url(https://www.rd.com/wp-content/uploads/2021/09/GettyImages-1181334518-MLedit.jpg)",
        backdropFilter: "blur(10px)",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <form onSubmit={handleFormSubmit} action="javascript:void(0)">
          <Grid>
            <Grid textAlign="center">
              <h1>OTP </h1>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly "
              height="200px"
              border="1"   
            >
              <Grid>
              <TextInput
                    id="input_otp"
                    field="code"
                    value={formState.code.value}
                    onChange={hasFormValueChanged}
                    required={true}
                    maxLength={100}
                    label="OTP"
                    placeholder="Enter Otp"
                  />
                  {codeError && <div className="error-msg">{codeError}</div>}
                  </Grid>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                size="large"
              >
                {" "}
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
}
export default Verifyopt;
