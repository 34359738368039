import { AppBar, Chip, Container, Grid, Tab, Tabs, Toolbar } from "@mui/material";
import React from "react";

import { Link, matchPath, useLocation } from "react-router-dom";
import "./Sidebar.css";
function Sidebar() {
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const [value, setValue] = React.useState("/dropoff-list");

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
      
    }

    return null;
  }
  const routeMatch = useRouteMatch([
    "/dashbord",
    "/dropoff-list",
    "/intagging",
    "/quicksort",
    "/confirm_pickup",
    "/user",
    "/Report",
    "/Setting",
  ]);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Grid
      sx={{
        height: "60px",
        bottom: "0",
      }}
    >
      {/* <AppBar
        color="inherit"
        position="fixed"
        sx={{top: "auto", bottom: 0, overflow: "auto" }}
      >
        <Toolbar
          sx={{
            textAlign: "center",
            bgcolor:"#90caf9",
            justifyContent: "space-between",
            overflow: "auto",
          }}
         
        >
          <Link
            to="/dashbord"
            style={{ textDecoration: "none" ,fontWeight:"520" }}
            className={splitLocation[1] === "dashbord" ? "active" : ""     }
            id={splitLocation[1] === "" ? "active" : ""     } 
          >
            <Chip label="DASHBORD" clickable sx={{ fontSize: "17px" ,padding:"20px" }} />
          </Link>
          <Link
            to="/dropoff-list"
            style={{ textDecoration: "none",fontWeight:"520"  }}
            className={splitLocation[1] === "dropoff-list" ? "active" : ""}
          >
            {" "}
            <Chip label="DROP OFF LIST" clickable sx={{ fontSize: "17px" ,padding:"20px" }} />
          </Link>
          <Link
            to="/intagging"
            style={{ textDecoration: "none",fontWeight:"520"  }}
            className={splitLocation[1] === "intagging" ? "active" : ""}
          >
            <Chip label="INTAGGING" clickable sx={{ fontSize: "17px" ,padding:"20px" }} />
          </Link>
          <Link
            to="/quicksort"
            style={{ textDecoration: "none",fontWeight:"520"  }}
            className={splitLocation[1] === "quicksort" ? "active" : ""}
          >
            <Chip label="QUICK SORT" clickable sx={{ fontSize: "17px" ,padding:"20px" }} />
          </Link>
          <Link
            to="/ready-forpickup"
            style={{ textDecoration: "none",fontWeight:"520"  }}
            className={splitLocation[1] === "ready-forpickup" ? "active" : ""}
          >
            <Chip
              label="READY FOR PICKUP"
              clickable
              sx={{ fontSize: "17px" ,padding:"20px" }}
            />
          </Link>
          <Link
            to="/user"
            style={{ textDecoration: "none",fontWeight:"520"  }}
            className={splitLocation[1] === "user" ? "active" : ""}
          >
            <Chip label="USERS" clickable sx={{ fontSize: "17px" ,padding:"20px" }} />
          </Link>
          <Link
            to="/report"
            style={{ textDecoration: "none",fontWeight:"520"  }}
            className={splitLocation[1] === "report" ? "active" : ""}
          >
            <Chip label="ABOUT" clickable sx={{ fontSize: "17px" ,padding:"20px" }} />
          </Link>
          <Link
            to="/setting"
            style={{ textDecoration: "none",fontWeight:"520"  }}
            className={splitLocation[1] === "setting" ? "active" : ""}
          >
            <Chip label="SETTINGS" clickable sx={{ fontSize: "17px" ,padding:"20px" }} />
          </Link>
        </Toolbar>
      </AppBar> */}

      <AppBar
        color="primary"
        position="fixed"
        sx={{ height: "60px", top: "auto", bottom: 0  ,borderBottom: 1, borderColor: 'divider' }}
      >
        <Container>
        <Tabs
        
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="full width tabs example"
           
        >
          {/* <Tab
            label="DASHBORD"
            value="/dashbord"
            to="/dashbord"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
            className={splitLocation[1] === "report" ? "active" : ""}
          /> */}
          <Tab
            label="DROP OFF LIST"
            value="/dropoff-list"
            to="/dropoff-list"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
          />
          <Tab
            label="Sort Bags"
            value="/sort-bags"
            to="/sort-bags"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
              
            }}
          />
          {/* <Tab
            label="QUICK-SORT"
            value="/quicksort"
            to="/quicksort"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
          /> */}
          <Tab
            label="MARK CLEANED"
            value="/mark-cleaned"
            to="/mark-cleaned"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
          />
          
          <Tab
            label="CREATE PICKUP ORDER"
            value="/create-pickup-order"
            to="/create-pickup-order"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
          />
          <Tab
            label=" CONFIRM PICKUP"
            value="/confirm_pickup"
            to="/confirm_pickup"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
          />
          {/* <Tab
            label="USERS"
            value="/user"
            to="/user"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
          />
          <Tab
            label="REPORTS"
            value="/Report"
            to="/Report"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
          />
          <Tab
            label="SETTINGS"
            value="/Setting"
            to="/Setting"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
          /> */}
            <Tab
            label="ORDERS"
            value="/orders"
            to="/orders"
            component={Link}
            style={{
              textDecoration: "none",
              fontWeight: "520",
              fontSize: "16px",
            }}
          /> 
           <Tab
          label="DRIVERS"
          value="/drivers"
          to="/drivers"
          component={Link}
          style={{
            textDecoration: "none",
            fontWeight: "520",
            fontSize: "16px",
          }}
        />
        </Tabs>
        </Container>
      </AppBar>
    </Grid>
  );
}
export default Sidebar;