import React from 'react';
import './App.css'; 
import AppRouter from './Router/AppRouter/approuter';
import PublicRouter from './Router/AppRouter/publicrouter';
function App() {
  const token = sessionStorage.getItem("@userToken");
  return (
    <div>
    { token ? <AppRouter/> : <PublicRouter />}
    </div>
  );
}

export default App;
