
import ApiEndPoints from "../../Network_call/ApiEndPoints";
import ApiServices from "../../Network_call/apiservices";



export const fetchUserManagementData = async (payload: any) => {
    return await ApiServices('post', payload, ApiEndPoints.Login);
};

export const fetchForgetPasswordData = async (payload: any) => {   
    return await ApiServices('post', payload, ApiEndPoints.Forgot);
};

export const fetchOtp = async (payload: any) => {   
    return await ApiServices('post', payload, ApiEndPoints.Otp);
};

export const fetchResetPasswordData = async (payload: any) => {   
    return await ApiServices('post', payload, ApiEndPoints.Reset_Password);
};