import { Alert, Button, Container, Dialog, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserManagementDataAction } from "../../Redux/Action/userLoginAction";
import TextInput from "../../common/textInput";
import { OnChangeModel } from "../../common/types/Formtypes";
import "../Login/Login.css";
import { Form } from "muicss/react";
import { AnyAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../Redux/store";


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [formState, setFormState] = useState({
    email: { error: "", value: "" },
    password: { error: "", value: "" },
  });

  const handleEmailChange = (model: OnChangeModel, e: any): void => {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });

    setEmailError("");
    setEmail(e.target.value);
  };
  const handlePasswordChange = (model: OnChangeModel, e: any): void => {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });

    setPasswordError("");
    setPassword(e.target.value);
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (formState.password.value.length == 0) {
      setPasswordError("Please enter password");
    }

    if (formState.email.value.length == 0) {
      setEmailError("Please enter email");

      return false;
    } else if (emailRegex.test(formState.email.value) === false) {
      setEmailError("Please enter valid email");
    }

    dispatch(setUserManagementDataAction(formState, navigate));
  };

  return (
    <>
      <Dialog
        fullWidth
        open={true}
        maxWidth="xs"
        sx={{
          background:
            "url(https://www.rd.com/wp-content/uploads/2021/09/GettyImages-1181334518-MLedit.jpg)",
          backdropFilter: "blur(10px)",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <form action="javascript:void(0)" onSubmit={handleFormSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly "
              height="350px"
              border="1"
              overflow="hidden"
            >
              <Grid textAlign="center">
                <h1>Login</h1>
              </Grid>
              <Grid>
                <TextInput
                  id="input_email"
                  field="email"
                  value={formState.email.value}
                  onChange={handleEmailChange}
                  required={true}
                  maxLength={100}
                  label="Email"
                  placeholder="Email"
                />
                {emailError && <div className="error-msg">{emailError}</div>}
              </Grid>
              <Grid>
                <TextInput
                  id="input_password"
                  field="password"
                  value={formState.password.value}
                  onChange={handlePasswordChange}
                  required={true}
                  maxLength={100}
                  type="password"
                  label="Password"
                  placeholder="Password"
                />
                {passwordError && (
                  <div className="error-msg">{passwordError}</div>
                )}
              </Grid>
              <Grid textAlign="end">
                <Link to="/forget-password"> Forget Password</Link>
              </Grid>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                size="large"
              >
                Log in
              </Button>
            </Grid>
          </form>
        </Container>
      </Dialog>
    </>
  );
};
export default Login;
