import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { count } from "console";

import { driverItem, driverDefaultState, driverPage } from "../interface/Driver_interface";
import { AppState } from "../store";

const initialState: driverDefaultState = {
  driverList: [],
  driverdata: [],
  totalRecords: 0,
  driverDetails: {}
   
};

export const driverReducer = createSlice({
  name: "driverState",
  initialState, 
  reducers: {
    setDriverListData: ( state, action: PayloadAction<driverPage> ) => ({
      ...state,
      driverList: action.payload.rows,
      totalRecords: action.payload.count
    }),
    setDriverDetailsData: ( state, action: PayloadAction<driverItem> ) => ({
      ...state,
      driverDetails: action.payload
    }),
    updateDriverStatus:( state, action: PayloadAction<driverItem> ) => ({
      ...state, 
      driverList: state.driverList.map(item=> item.id === action.payload.id ? 
        { ...item, status: action.payload.status} : item
    ) 
    }),
    deleteDriver:( state, action: PayloadAction<driverItem> ) => ({
      ...state, 
      driverList: state.driverList.filter(item => item.id != action.payload.id) 
    })
  },
});

export const { setDriverListData, updateDriverStatus, deleteDriver, setDriverDetailsData } = driverReducer.actions;

export const getDriverListData = (state: AppState) => {
  return({rows: state.driverReducer.driverList, count: state.driverReducer.totalRecords})
};

export const  getDriverDetailsData = (state: AppState) => state.driverReducer.driverDetails

export { initialState as driverState };
export default driverReducer;

 