import { fetchResetPasswordData } from "../fetchservice/UserManagement";
import { setUserResetData } from "../Reducer/userResetPasswordReducer";
import { AppThunk } from "../store";
import { setUserData } from "../../helper/Storage";
import swal from "sweetalert";

export const SetUserResetPasswordData =
  (payload: any, navigate: any): any =>
  async (dispatch: any) => {
    let request = {
      user_id: payload.user_id.value,
      password: payload.password.value,
    };

    const response: any = await fetchResetPasswordData(request);

    if (response != undefined && response.success) {
      let userObj: any = JSON.stringify(response.data);

      swal(response.message, "  ", "success");

      setUserData(userObj);
      dispatch(setUserResetData(userObj));
      navigate("/login");
    } else {
      dispatch(setUserResetData([]));
      swal(response.message, "  ", "error");
      if (response && response[0] && response[0].errorMessage) {
        let payload = {
          status: "error",
          message: response[0].errorMessage,
          display: true,
        };
      }
    }
  };
