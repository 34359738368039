import { createSlice, PayloadAction } from "@reduxjs/toolkit";
 
import { Markcleanedstate ,markcleanedItem} from "../interface/markcleaned_interface";
import { AppState } from "../store";

const initialState: Markcleanedstate = {
  markcleaned:[],
    totalRecords: 0
};

export const markcleanedreducer = createSlice({
  name: "loundryState",
  initialState,
  reducers: {
    setmarkcleaneddata: ( state, action: PayloadAction<markcleanedItem[]> ) => ({
      ...state,
      markcleanedItem: action.payload,
    }),
  },
});

export const { setmarkcleaneddata } = markcleanedreducer.actions;

export const getmarkcleaneddata = (state: AppState) => state.markcleanedreducer.markcleaned;

export { initialState as loundryState };
export default markcleanedreducer;
