import ApiEndPoints from "../../Network_call/ApiEndPoints"
import ApiServices from "../../Network_call/apiservices"



export const userlist=async(payload:any)=>{
    return await ApiServices("get",payload, ApiEndPoints.UserList)
    }

export const useredit=async(payload:any)=>{
return await ApiServices("put",payload, ApiEndPoints.UserEdit)
}

export const userremove=async(payload:any)=>{
    return await ApiServices("delete",payload, ApiEndPoints.UserRemove)
    }
export const useradd=async(payload:any)=>{
return await ApiServices("post",payload, ApiEndPoints.UserAdd)
}