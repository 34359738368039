
const APPConfig = {
  API_URL1: "http://54.210.58.131:3001/api",
  API_URL3: "http://54.210.58.131:3003/api",
  API_URL4: "http://54.210.58.131:3004/api",
  API_URL5: "http://54.210.58.131:3005/api",
 

}
const ApiEndPoints = {
  Login: `${APPConfig.API_URL4}/login`,
  Forgot: `${APPConfig.API_URL4}/forgot-password`,
  Otp: `${APPConfig.API_URL4}/check-otp`,
  Reset_Password: `${APPConfig.API_URL1}/reset-password`,
  Dropofflist:`${APPConfig.API_URL4}/order-dropOff-list`,
  DropofflistCode:`${APPConfig.API_URL4}/drop-off`,
  AcceptReject:`${APPConfig.API_URL4}/order-accept-reject`,
  Role_permission:`${APPConfig.API_URL4}/role-permission`,
  UserList:`${APPConfig.API_URL4}/user-list`,
  UserEdit:`${APPConfig.API_URL4}/edit-user`,
  UserRemove:`${APPConfig.API_URL4}/delete-user`,
  UserAdd:`${APPConfig.API_URL4}/add-user`,
  IntaggingLaundry:`${APPConfig.API_URL4}/tagging/get-bag-details`,
  ProductList:`${APPConfig.API_URL3}/products`,
  confirm_create_tag:`${APPConfig.API_URL3}/confirm-create-tag`,
  confirm_tag:`${APPConfig.API_URL4}/confirm-tag`,
  Bagtag_list:`${APPConfig.API_URL4}/Bagtag-list`,
  create_pickupcode:`${APPConfig.API_URL4}/create-pickupcode`,
  pickup_list:`${APPConfig.API_URL4}/pickup-list`,
  Driver_list:`${APPConfig.API_URL4}/driver-list/for-laundry/`,
  Driver_add:`${APPConfig.API_URL5}/driver/add`,
  Driver_delete:`${APPConfig.API_URL4}/pickup-list`,
  Driver_edit:`${APPConfig.API_URL4}/pickup-list`,
  Tag_not_allocate:`${APPConfig.API_URL3}/tags-not-allocate`,
  Assign_tag_customer:`${APPConfig.API_URL5}/assignTagsToCustomer`,

}

export default ApiEndPoints


