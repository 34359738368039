import { Button, Container, Dialog, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextInput from "../../../common/textInput";
import { OnChangeModel } from "../../../common/types/Formtypes";
import { setUserForgetpasswordData } from "../../../Redux/Action/UserPasswordAction";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import "../forget/forget.css";
import "../../Login/Login.css";
import { AsyncLocalStorage } from "async_hooks";

function Forgetpassword(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [formState, setFormState] = useState({
    email: { error: "", value: "" },
  });

  const handleEmailChange = (model: OnChangeModel, e: any): void => {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });
    setSuccessMsg("");
    setEmailError("");
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (formState.email.value.length == 0) {
      setEmailError("Please enter the email");
      return false;
    } else if (emailRegex.test(formState.email.value) == false) {
      setEmailError("Please enter the valid email");
    }
    dispatch(setUserForgetpasswordData(formState,navigate));
  };
 

  return (
    <div>
      <Dialog
        fullWidth
        open={true}
        maxWidth="xs"
        sx={{
          background:
            "url(https://www.rd.com/wp-content/uploads/2021/09/GettyImages-1181334518-MLedit.jpg)",
          backdropFilter: "blur(10px)",
          backgroundSize: "cover",
        }}
      >
        <Grid>
          <Link className="close" to="/login">
            <CloseIcon />
          </Link>
        </Grid>
        <Container>
          <form action="javascript:void(0)" onSubmit={handleFormSubmit}>
            <Grid>
              <Grid textAlign="center">
                <h1>Forgot Password</h1>
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="space-evenly "
                height="200px"
                border="1"
              >
                <Grid>
                  <TextInput
                    id="input_email"
                    field="email"
                    value={formState.email.value}
                    onChange={handleEmailChange}
                    required={true}
                    maxLength={100}
                    label="Email"
                    placeholder="Email"
                  />
                  {emailError && <div className="error-msg">{emailError}</div>}
                </Grid>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  size="large"
                >
                  {" "}
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dialog>
    </div>
  );
}
export default Forgetpassword;
