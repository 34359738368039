import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TagAllocateInterface,TagAllocateItem } from "../interface/Tag_Allocate_interface";
import { AppState } from "../store";

const initialState: TagAllocateInterface = {
  taglist:[],
    totalRecords: 0
};

export const TagAllocateReducer = createSlice({
  name: "loundryState",
  initialState,
  reducers: {
    setTagAllocateData: ( state, action: PayloadAction<TagAllocateItem[]> ) => ({
      ...state,
      TagAllocateItem: action.payload,
    }),
  },
});

export const { setTagAllocateData } = TagAllocateReducer.actions;

export const getTagAllocateData = (state: AppState) => state.TagAllocateReducer.taglist;

export { initialState as loundryState };
export default TagAllocateReducer;
