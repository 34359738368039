import {
  AppBar,
  Button,
  Container,
  Grid,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";                              
import { Link, matchPath, Outlet, useLocation } from "react-router-dom";

const Tag_garments = () => {
  var [bag_no, setBag_no] = useState("");
  const [page, setPage] = React.useState(1);
  const [full_name, setFullName] = useState("");
  var userdata: any = sessionStorage.getItem("@userData");
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  }
  const routeMatch = useRouteMatch([
    "/tag-garments/laundry",
    "/tag-garments/drycleaning",
    "/tag-garments/wash-folds",
  ]);
  const currentTab = routeMatch?.pattern?.path;

  // useEffect(() => {
  //   if (userdata) {              
  //     userdata = JSON.parse(userdata);
  //     setFullName(userdata.full_name);

  //     console.log("--------->", userdata);
  //   }
  // }, []);

  const [value, setValue] = React.useState(0);

  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Outlet />
      {/* <Grid>
      <Grid>
      
        <Outlet />{" "}
      </Grid>
      <Grid
      container
      direction="row"
      paddingBottom="20px"
      justifyContent="space-around"
    >
      <Link to="/Tag_garments/Laundry">
        <Button variant="contained" color="success">
          Laundry
        </Button>
      </Link>
      <Link to="/Tag_garments/Drycleaning">
        <Button variant="contained" color="warning">
          Drycleaning
        </Button>
      </Link>
      <Link to="/Tag_garments/Wash-folds">
        <Button variant="contained"> Wash & Folds</Button>
      </Link>
    </Grid>
    </Grid> */}

      {/* <AppBar
        color="default"
        position="sticky"        
        sx={{
          marginBottom: "10px",
          boxShadow: "none",
          borderTop: "1px solid gray",
          bgcolor: "white"
        }}
      >
        <Container>
          <Tabs
            value={value}
            onChange={handleChange1}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
         
          >
            <Tab
              label="Laundry"
              // value="/Tag_garments/laundry"
              value={0}
              to="/tag-garments/laundry"
              component={Link}
              sx={{ textDecoration: "none" }}
            />
            <Tab
              label="Drycleaning"
              // value="/tag_garments/drycleaning"
              value={1}
              to="/tag-garments/drycleaning"
              component={Link}
              sx={{ textDecoration: "none" }}
            />
            <Tab
              label="Wash&fold"
              value={2}
              // value="/tag_garments/wash-folds"
              to="/tag-garments/wash-folds"
              component={Link}
              sx={{ textDecoration: "none" }}
            />
          </Tabs>
        </Container>
      </AppBar> */}
    </div>
    // <Grid sx={{ padding: "20px" }}>
    //   <Stack direction="row" justifyContent="space-between">
    //     <Typography variant="h6"> User Name</Typography>
    //     <Typography>
    //       {" "}
    //       <Button variant="contained">View History</Button>{" "}
    //     </Typography>
    //   </Stack>
    //   <Typography variant="h6"> Enter No Of Bag's </Typography>
    //   <Stack direction="row" spacing={3}>
    //     <form action="javascript:void(0)">
    //       <TextField
    //         size="small"
    //         label="Enter Bag No."
    //         value={bag_no}
    //         onChange={(e: any) => setBag_no(e.target.value)}
    //       />
    //       <Button type="submit" variant="contained">
    //         Submit
    //       </Button>
    //       <p>OR</p>
    //     </form>
    //     <Button variant="contained">Scan</Button>
    //   </Stack>
    //   <Grid>
    //     <Typography>lot # 07022202502</Typography>
    //     <Stack direction="row" justifyContent="space-between">
    //       <Typography> {full_name}</Typography>
    //       <Typography>Needs Delivery by :</Typography>
    //     </Stack>
    //     <Stack direction="row" justifyContent="space-between">
    //       <Typography>Bag id : 12</Typography>
    //       <Typography>bag {page}/ 5</Typography>
    //     </Stack>
    //   </Grid>

    //   <Paper elevation={3}>
    //     <form>
    //       <Table>
    //         <TableHead>
    //           <TableRow
    //             sx={{
    //               position: "sticky",
    //               top: "0",
    //               background: "#e8eaf6",
    //               zIndex: "2",
    //             }}
    //           >
    //             <TableCell sx={{ fontSize: "20px" }}>Enter Cloths</TableCell>

    //             <TableCell sx={{ fontSize: "20px" }}>quantity</TableCell>
    //             <TableCell sx={{ fontSize: "20px" }}>
    //               Special Instruction
    //             </TableCell>
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>
    //           <TableRow>
    //             <TableCell sx={{ fontSize: "18px" }}>shirts </TableCell>
    //             <TableCell>
    //               <TextField />
    //             </TableCell>

    //             <TableCell>
    //               <TextField />
    //             </TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell sx={{ fontSize: "18px" }}>pants </TableCell>
    //             <TableCell>
    //               <TextField />
    //             </TableCell>

    //             <TableCell>
    //               <TextField />
    //             </TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell sx={{ fontSize: "18px" }}>Lab Coat </TableCell>
    //             <TableCell>
    //               <TextField />
    //             </TableCell>

    //             <TableCell>
    //               <TextField />
    //             </TableCell>
    //           </TableRow>
    //           <TableRow>
    //             <TableCell sx={{ fontSize: "18px" }}>Apron </TableCell>
    //             <TableCell>
    //               <TextField />
    //             </TableCell>

    //             <TableCell>
    //               <TextField />
    //             </TableCell>
    //           </TableRow>
    //         </TableBody>
    //       </Table>

    //     </form>
    //   </Paper>
    //   <Stack spacing={2}></Stack>
    // </Grid>
  );
};

export default Tag_garments;

const Verify = () => {
  return (
    <Grid>
      <Typography>lot # 07022202502</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography>customer name: user Name</Typography>
        <Typography>Needs Delivery by :</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography>Bag id : 12</Typography>
        <Typography>bag :</Typography>
      </Stack>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              position: "sticky",
              top: "0",
              background: "#e8eaf6",
              zIndex: "2",
            }}
          >
            <TableCell>Enter Cloths</TableCell>

            <TableCell></TableCell>
            <TableCell>Special Instruction</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>shirts :</TableCell>
            <TableCell>
              <TextField />
            </TableCell>

            <TableCell>
              <TextField />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>pants :</TableCell>
            <TableCell>
              <TextField />
            </TableCell>

            <TableCell>
              <TextField />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Lab Coat :</TableCell>
            <TableCell>
              <TextField />
            </TableCell>

            <TableCell>
              <TextField />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Apron :</TableCell>
            <TableCell>
              <TextField />
            </TableCell>

            <TableCell>
              <TextField />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        margin=" 20px 0"
      >
        {/* <Button variant="contained">Confirm and Proceed</Button> */}
      </Grid>
      <Grid container direction="row" justifyContent="space-around">
        <Button variant="contained" color="success">
          Laundry
        </Button>
        <Button variant="contained" color="warning">
          Drycleaning
        </Button>
        <Button variant="contained"> Wash & Folds</Button>
      </Grid>
    </Grid>
  );
};
