import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import ContentLoader from "react-content-loader";

const LoaderBullet = () => {
  return (
    <>
      {/* <Backdrop sx={{ color: '#fff'}}
  open={true}
>
  <CircularProgress color="inherit" />
</Backdrop> */}
      <Skeleton animation="wave" height={10}  sx={{margin:"10px 0",width:"100%"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
      <Skeleton animation="wave" height={10} width={1300 } sx={{margin:"10px 0"}}/>
     
    </>
  );
};

export { LoaderBullet };
