import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IntaggingState,IntaggingItem,} from "../interface/Intagging_Interface";
import { AppState } from "../store";

const initialState: IntaggingState = {
  Intagging: [],
  totalRecords: 0,
};

export const IntaggingReducer = createSlice({
  name: "IntaggingState",
  initialState,
  reducers: {
    setIntaggingData: (state, action: PayloadAction<IntaggingItem[]>) => ({
      ...state,
      IntaggingItem: action.payload,
    }),
  },
});

export const { setIntaggingData } = IntaggingReducer.actions;

export const getIntaggingData = (state: AppState) =>
  state.IntaggingReducer.Intagging;

export { initialState as IntaggingState };
export default IntaggingReducer;
