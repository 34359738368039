import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import pic from "../../../src/Assets/Images/pickup1.jpg";
import { Link } from "react-router-dom";

function createData(Your_Account: any, Security: any) {
  return {
    Your_Account,
    Security,
  };
}

export default function CustomizedTables() {
  return (
    <div style={{ backgroundColor: "whitesmoke" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ padding: "10px" }}
      >
        <Typography variant="h4">Setting</Typography>
      </Grid>
      <Container maxWidth="md">
        <TableContainer
          component={Paper}
          sx={{
            bgcolor: "white",
            width: "90%",
            marginLeft: "5%",
          }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  position: "sticky",
                  top: "0",
                  background: "#90caf9",
                  zIndex: "2",
                }}
              >
                <TableCell sx={{ fontSize: "20px" }} align="left">
                  Your Account
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ padding: "16px", fontSize: "14px" }}
                  align="left"
                >
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <TextField
                        label="Name"
                        placeholder="Sunshine Cleaning"
                        id="outlined-size-small"
                        defaultValue="Sunshine Cleaning"
                        size="small"
                      />
                    </div>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ padding: "16px", fontSize: "14px" }}
                  align="left"
                >
                  <Box
                    component="form"    
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <TextField
                        label="Mail-Id"
                        placeholder="sunshine.clean@gmail.com"
                        id="outlined-size-small"
                        defaultValue="sunshine.clean@gmail.com"
                        size="small"
                      />
                    </div>
                  </Box>
                 
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ padding: "16px", fontSize: "14px" }}
                  align="left"
                >
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <TextField
                        label="Contact Number"
                        placeholder="9998887770"
                        id="outlined-size-small"
                        defaultValue="9998887770"
                        size="small"
                      />
                    </div>
                  </Box>
                </TableCell>
              </TableRow>

              <Grid sx={{ padding: "8px" }}>
                <Link to="/setting" style={{textDecoration:"none"}}>
                  <Button variant="contained" disableElevation>
                    Done
                  </Button>
                </Link>
              </Grid>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}
